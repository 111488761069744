import { useState, useEffect } from 'react'
import Header from './Header'
import SelectApp from './SelectApp'
import './customer.sass'
import { useParams } from 'react-router-dom'
import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify'
import { URL_PATH } from 'utils/constants'
import NoData from 'components/NoData/NoData'
import jobsIcon from 'assets/jobs.svg'

const CustomerPortal = () => {
  const [jobStatus, setJobStatus] = useState(true);
  const [jobData, setJobData] = useState({})
  const [showStepper, setShowStepper] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [jobMessage, setJobMessage] = useState<any>();

  const { id }: any = useParams();

   
  const fetchJob = async () => {
    try {
      const config: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}customerjobstatus`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { id: id },
      };

      const response = await axios.request(config);
      // console.log("response",response);
      
      if (response?.data.isExpired == false) {
        setJobStatus(true)
        setJobData(response?.data?.data)

        if(response?.data?.data?.step !== 'first' && typeof response?.data?.data?.step === 'number'){
          setShowStepper(true)
          setCurrentStep(response?.data?.data?.step)
        }
      } else {
        setJobMessage(response?.data?.message);
        setJobStatus(false)
      }
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }


  // useEffect(() => {
  //   fetchJob()
  // }, [])
 
  return (
    <div className='customer_data'>
      {jobStatus ?
        <>
          {/* <Header
            setShowStepper={setShowStepper}
            showStepper={showStepper}
            currentStep={currentStep} /> */}
            <div className="customer-content"
             style={{
              backgroundImage: 'url(/assets/createappbg.png)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',

            }}>
            <SelectApp
              jobData={jobData}
              setShowStepper={setShowStepper}
              showStepper={showStepper}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          </div>
        </>
        :
        <>
          <Header
            setShowStepper={setShowStepper}
            showStepper={showStepper}
            currentStep={currentStep} />
          <NoData
            icon={jobsIcon}
            title={jobMessage}
          />
        </>
      }
    </div>
  )
}
export default CustomerPortal
import React from 'react';

export const appTypes = [
    {
        "title": "Predefined App",
        "desc": "Choose from ready-made templates for a quick, easy start."
    },
    {
        "title": "Custom App",
        "desc": "Build a custom app tailored to your needs. Share your vision, and we’ll make it happen."
    }
]

export type appTypesProps = {
    title: string;
    desc: string;
}

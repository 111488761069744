import CreateAppFooter from '../../footer';
import FromHeader from '../formHeader';
import { onFocusOut, onInputChange } from '../../../../../../components/lib/validation/createApp';

interface Step1Props {
    formData: any;
    nextStep: () => void;
    prevStep: () => void;
    step: number;
    name: string;
    dispatchForm: any;
}

const Step1 = ({ formData, dispatchForm, nextStep, step, name, prevStep }: Step1Props) => {
 
    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div className="main_card">
                    <FromHeader
                        title={"Give Your App a Name"}
                        desc={"Enter a captivating title for your app. This is for display in Proton. Make it memorable and reflective of its purpose."}
                        step={step}
                        name={name}
                    />
                    <div style={{ margin: '70px 0px 0px' }} >
                        <div className="main_section_card"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 8,
                                margin: '20px 40px 40px',
                                cursor: 'pointer',
                                height: "100%",
                                overflow: "auto"

                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    gap: '25px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '4px',
                                    gap: '20'
                                }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Write the title of your app
                                    </label>

                                    {/* Textarea input */}
                                    <textarea
                                        name="name"
                                        value={formData.fields.name.value}
                                        className={`${formData.fields.name.touched && formData.fields.name.hasError ? "error" : ""}`}
                                        onChange={e => {
                                            onInputChange("name", e.target.value, dispatchForm, formData);
                                        }}
                                        onBlur={e => {
                                            onFocusOut("name", e.target.value, dispatchForm, formData);
                                        }}
                                        rows={4}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            resize: 'vertical',
                                            fontSize: '14px',
                                        }}
                                    />
                                    {formData.fields.name?.touched && formData.fields.name?.hasError && (
                                        <div className="error">{formData.fields.name?.error}</div>
                                    )}
                                    {/* Text Content */}
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', marginTop: '15px', gap: '5px' }}>
                                        <p style={{ fontWeight: 'bold' }}> Example Titles:</p>
                                        <ul style={{ listStyle: 'inside' }} >
                                            <li>Local Event Explorer </li>
                                            <li>Fitness Tracker Pro </li>
                                            <li>Grocery List Organizer </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateAppFooter nextStep={nextStep} step={step} prevStep={prevStep} price={"0"} type={0} setActiveTab={0} />
        </div >
    );
};

export default Step1;
import { HTMLAttributes, useContext } from 'react'
import { NavLink } from 'react-router-dom'

import dashboardIcon from 'assets/dashboard.svg'
import jobsIcon from 'assets/jobs.svg'
import notificationsIcon from 'assets/notifications.svg'
import staffIcon from 'assets/staff.svg'
import templatesIcon from 'assets/templates.svg'
import subscriptionIcon from 'assets/subscriptionIcon.png'
import { AuthContext } from 'context/AuthContext'
import phoneIcon from 'assets/phoneIcon.svg';
import listIcon from 'assets/list.svg';
import bell from 'assets/bell.svg';
import myApps from 'assets/my-apps.svg';

import './navigation.sass'

interface IMenuItem {
  label: string
  route: string
  icon: string
}

const Admin_MENU: Array<IMenuItem> = [
  // {
  //   label: 'Dashboard',
  //   route: '/',
  //   icon: dashboardIcon,
  // },
  {
    label: 'Jobs',
    route: '/jobs',
    icon: jobsIcon,
  },
  {
    label: 'Staff',
    route: '/staff',
    icon: staffIcon,
  },
  {
    label: 'Customer',
    route: '/users',
    icon: staffIcon,
  },
  {
    label: 'Templates',
    route: '/templates',
    icon: templatesIcon,
  },
  // {
  //   label: 'Notifications',
  //   route: '/notifications',
  //   icon: notificationsIcon,
  // },
  {
    label: 'Subscription',
    route: '/subscription',
    icon: subscriptionIcon,
  },
]

const STAFF_MENU: Array<IMenuItem> = [
  // {
  //   label: 'Dashboard',
  //   route: '/',
  //   icon: dashboardIcon,
  // },
  {
    label: 'Jobs',
    route: '/jobs',
    icon: jobsIcon,
  },
  // {
  //   label: 'Notifications',
  //   route: '/notifications',
  //   icon: notificationsIcon,
  // },
]

const Customer_MENU: Array<IMenuItem> = [
  {
    label: 'Create App',
    route: '/createApp',
    icon: "/assets/list_1.png",
  },
  {
    label: 'Apps',
    route: '/customer',
    icon: myApps,
  },
  // {
  //   label: 'Pending tasks',
  //   route: '/pendingtask',
  //   icon: listIcon,
  // },
  {
    label: 'Website',
    route: '/assets',
    icon: "/assets/list_1.png",
  },
  // {
  //   label: 'Add new',
  //   route: '/customer/onboarding/',
  //   icon: "/assets/plus-icon.png",
  // },
  {
    label: 'Payments',
    route: '/payments',
    icon: '/assets/credit-card.png',
  },
  // {
  //   label: 'Notifications',
  //   route: '/Notifications',
  //   icon: bell,
  // },
  // {
  //   label: 'Inventory',
  //   route: '/inventory/addItem',
  //   icon: phoneIcon,
  // },
]

// const CUSTOMER_MENU: Array<IMenuItem> = []

interface INavigationProps extends HTMLAttributes<HTMLDivElement> { }
const svgLogo = <svg width="112" height="36" viewBox="0 0 112 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_31_1826" maskUnits="userSpaceOnUse" x="0" y="0" width="31" height="36">
    <path d="M30.7345 0H0V35.9593H30.7345V0Z" fill="white" />
  </mask>
  <g mask="url(#mask0_31_1826)">
    <path d="M15.3672 0L0 9.46317V26.4962L15.3672 35.9593L30.7345 26.4962V9.46317L15.3672 0ZM15.0429 29.9462L9.5603 26.5494V18.0692L15.0429 14.6369V29.9462ZM22.3073 18.1245L16.8246 21.9048V13.2925L15.3672 12.4772L10.1083 9.5342L15.3672 6.17503L15.4997 6.09052L22.3073 10.1748V18.1245Z" fill="#00B8D9" />
  </g>
  <path d="M48.5477 19.1838C48.5477 19.6048 48.3898 19.9731 48.0741 20.2888C47.7584 20.6045 47.3901 20.7623 46.9692 20.7623H40.8806V23.9194H39.0765V16.0268L40.6551 14.4482H39.0765V12.8697H46.9692C47.3901 12.8697 47.7584 13.0276 48.0741 13.3433C48.3898 13.659 48.5477 14.0273 48.5477 14.4482V19.1838ZM46.7437 19.1838V14.4482H40.8806V20.5368L42.2336 19.1838H46.7437ZM61.9642 23.9194H59.6641L54.9736 19.1838H59.9347V14.4482H54.0716V23.9194H52.2676V16.0042L53.8461 14.4482H52.2676V12.8697H60.1602C60.5812 12.8697 60.9495 13.0276 61.2652 13.3433C61.5809 13.659 61.7387 14.0273 61.7387 14.4482V18.7328C61.7387 19.9355 61.2126 20.5368 60.1602 20.5368H58.8072V20.7849L61.9642 23.9194ZM74.5969 22.3409C74.5969 22.7768 74.439 23.1527 74.1233 23.4684C73.8226 23.769 73.4543 23.9194 73.0183 23.9194H66.7042C66.2682 23.9194 65.8924 23.769 65.5767 23.4684C65.276 23.1527 65.1257 22.7768 65.1257 22.3409V14.4482C65.1257 14.0123 65.276 13.6439 65.5767 13.3433C65.8924 13.0276 66.2682 12.8697 66.7042 12.8697H73.0183C73.4543 12.8697 73.8226 13.0276 74.1233 13.3433C74.439 13.6439 74.5969 14.0123 74.5969 14.4482V22.3409ZM72.7928 22.3409V14.4482H66.9297V22.3409H72.7928ZM84.9665 14.4482H81.6967V23.9194H79.8926V14.4482H76.6228V12.8697H84.9665V14.4482ZM96.4647 22.3409C96.4647 22.7768 96.3069 23.1527 95.9912 23.4684C95.69 23.769 95.3219 23.9194 94.8862 23.9194H88.5721C88.1356 23.9194 87.7603 23.769 87.4446 23.4684C87.1434 23.1527 86.9936 22.7768 86.9936 22.3409V14.4482C86.9936 14.0123 87.1434 13.6439 87.4446 13.3433C87.7603 13.0276 88.1356 12.8697 88.5721 12.8697H94.8862C95.3219 12.8697 95.69 13.0276 95.9912 13.3433C96.3069 13.6439 96.4647 14.0123 96.4647 14.4482V22.3409ZM94.6607 22.3409V14.4482H88.7976V22.3409H94.6607ZM109.878 23.8968H106.721L102.437 14.5384H101.535L102.211 15.666V23.8968H100.407V12.8472H103.587L107.849 22.2056H108.751L108.074 21.078V12.8472H109.878V23.8968Z" fill="#00B8D9" />
</svg>;

const Navigation = ({ ...props }: INavigationProps) => {
  const auth = useContext(AuthContext)
  const isCustomer = auth?.user?.permissions?.includes("customer");
  const isTemp = auth?.user?.permissions?.includes("temp");
  const isSuperAdmin = auth?.user?.permissions?.includes("administrator");
  return (
    <div className="navigation" {...props}>

      <div className="sidebar-log">
        {svgLogo}
      </div>
      {isCustomer || isTemp ? (
        <>
          {Customer_MENU.map((item) => (
            <NavLink
              to={item.route}
              key={item.label}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <img src={item.icon} alt={item.label} />
              <span>{item.label}</span>
            </NavLink>
          ))}
        </>
      ) : isSuperAdmin ? (
        <>
          {Admin_MENU.map((item) => (
            <NavLink
              to={item.route}
              key={item.label}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <img src={item.icon} alt={item.label} />
              <span>{item.label}</span>
            </NavLink>
          ))}
        </>
      ) : (
        <>
          {STAFF_MENU.map((item) => (
            <NavLink
              to={item.route}
              key={item.label}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <img src={item.icon} alt={item.label} />
              <span>{item.label}</span>
            </NavLink>
          ))}
        </>
      )}
    </div>
  );
}

export default Navigation

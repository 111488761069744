import { Dispatch, useContext, useReducer, useState } from "react";
import FromHeader from "pages/customer/CustomerPortal/CreateApp/Step/formHeader";
import PlaceholderSvg from 'pages/customer/CustomerPortal/utils/SVG/placeholder';
import { UPDATE_FORM, onFocusOut, onInputChange, validateInput, resetForm, initialState, formReducer } from "components/lib/validation/appDemo";
import { Button } from 'antd';
import { toast } from "react-toastify";
import { AuthContext } from "context/AuthContext";
import SimplePopup from "components/common/popup";
import { EmailHandler, handleFileUpload } from "pages/customer/CustomerPortal/utils/action";

interface Step1Props {
    showPaymentPage?: any;
}

const confirmValidity = (formState: any, dispatch: Dispatch<any>) => {
    let isFormValid = true;
    for (let name in formState) {
        const item = formState[name]
        const { value } = item
        const { hasError, error } = validateInput(name, value, formState)
        if (hasError) {
            isFormValid = false
        }
        if (name) {
            dispatch({
                type: UPDATE_FORM,
                data: {
                    name,
                    value,
                    hasError,
                    error,
                    touched: true,
                    isFormValid,
                },
            })
        }
    }
    return isFormValid;
}

const AppDemoCard = ({ showPaymentPage }: Step1Props) => {
    const [formData, dispatchForm] = useReducer(formReducer, initialState);
    const [file, setFile] = useState<string | null>(null);
    const [payment, setPayment] = useState<boolean>(false);
    const [SucessPop, setSucessPop] = useState<boolean>(false);
    const auth = useContext(AuthContext);

    // Handle image removal
    const handleRemoveImage = () => {
        setFile(null);
        onInputChange("attachment", '', dispatchForm, formData);
    };

    const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]; // Get the first file
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setFile(fileUrl);
            try {
                // Ensure that the fileupload is awaited and properly assigned
                const fileupload: any = await handleFileUpload(file);

                if (fileupload) {
                    onInputChange("attachment", fileupload, dispatchForm, formData);
                }
            } catch (error) {
                toast.error(`Error during file upload. ${error}`);
            }
        }
    };

    const onSubmit = async (e: any) => {
        e.preventDefault();
        const formVaild = confirmValidity(formData, dispatchForm);

        if (formVaild) {
            const name = auth?.user?.name;

            const EmailSentRes = await EmailHandler(name, formData?.attachment?.value, formData?.feedback?.value);

            if (EmailSentRes?.status == 200) {
                setSucessPop(true);
            } else {
                toast.error(EmailSentRes?.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                })
            }
            dispatchForm(resetForm());
        } else {
            toast.error(' Please fill in all required fields.', {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div className="main_card">
                    <FromHeader
                        title={"App Demo"}
                        desc={"Experience a live preview of your app in action. Interact with its features, explore the design, and get a feel for the user experience before finalizing."}
                    />
                    <form onSubmit={onSubmit} >
                        <div style={{
                            display: 'flex',
                            justifyContent: "center",
                            background: '#2226470D',
                            padding: "25px",
                            gap: 25
                        }}>
                            <div style={{
                                width: "30%"
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}>
                                    <div style={{
                                        background: "#EBEBEB"
                                    }}>
                                        <PlaceholderSvg width="330px" height="500" />
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "70%",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: '4px',
                                        gap: '20'
                                    }}>
                                        <label
                                            style={{
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Your Feedback
                                        </label>

                                        {/* Textarea input */}
                                        <textarea
                                            name="feedback"
                                            value={typeof formData.feedback.value === "string" ? formData.feedback.value : ''}
                                            className={`${formData.feedback.touched && formData.feedback.hasError ? "error" : ""}`}
                                            onChange={e => {
                                                onInputChange("feedback", e.target.value, dispatchForm, formData);
                                            }}
                                            onBlur={e => {
                                                onFocusOut("feedback", e.target.value, dispatchForm, formData);
                                            }}
                                            rows={4}
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                resize: 'vertical',
                                                fontSize: '14px',
                                                marginBottom: '5px'
                                            }}
                                        />
                                        {formData.feedback?.touched && formData.feedback?.hasError && (
                                            <div className="error">{formData.feedback?.error}</div>
                                        )}
                                    </div>
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        borderRadius: '4px',
                                    }}>
                                        <label
                                            style={{
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            File Attachment
                                        </label>

                                        <input
                                            name="attachment"
                                            type='file'
                                            className={`${formData.attachment.touched && formData.attachment.hasError ? "error" : ""}`}
                                            onChange={e => {
                                                handleChangeFile(e);
                                            }}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                borderRadius: '4px',
                                                height: 'auto'
                                            }}
                                        />
                                        {formData?.attachment?.touched && formData?.attachment?.hasError && (
                                            <div className="error">{formData?.attachment?.error}</div>
                                        )}
                                        {file && (
                                            <div style={{
                                                position: 'relative',
                                                marginTop: '20px',
                                                border: '1px solid #ccc',
                                                padding: '10px'
                                            }}>
                                                <img src={file} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                                <a type="button" onClick={handleRemoveImage} style={{
                                                    position: 'absolute',
                                                    right: '-15px',
                                                    top: '-10px',
                                                    fontSize: '24px',
                                                    fontWeight: '600',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '50%',
                                                    width: '30px',
                                                    height: '30px',
                                                    textAlign: 'center',
                                                    lineHeight: '23px',
                                                    cursor: 'pointer',
                                                    color: '#000',
                                                    background: '#fff'
                                                }}>
                                                    x
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!payment && <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Button htmlType="submit" className="newLight" style={{
                                        width: '100%',
                                    }}  >Share your Feedback</Button>
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {SucessPop && <SimplePopup title="Your Feedback is share to us" desc="We value your insights! Let us know your thoughts to help us improve and deliver a better experience." setSucessPop={setSucessPop} SucessPop={SucessPop} setFile={setFile} />}
        </div>
    )
}
export default AppDemoCard;
import KebabMenu from "components/KebabMenu/KebabMenu";
import CalenderIcon from "pages/customer/CustomerPortal/utils/SVG/calenderIcon";
import PlaceholderImage from "pages/customer/CustomerPortal/utils/SVG/placeholderImage";
import BadgeButton from "../badges";
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { setAppJob } from 'store/slices/createAppSlice';

interface Props {
    kebabMenuItems?: any;
    job: any;
    image: string;
    setSelectedTemp?: any;
    setShowPerview?: any;
    setShowAppDemo?: any;
    setShowAppDemoJob?: any;
    setShowSubscriptionPage?: any;
}

const NewJobCard = ({ kebabMenuItems, job, image, setSelectedTemp, setShowPerview, setShowAppDemo, setShowSubscriptionPage }: Props) => {
    const dispatch = useDispatch();

    const updateJob = (job: any) => {
        dispatch(setAppJob(job));
    };

    // Function to format date
    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleOpenTemplate = (id: string, name: string) => {
        const data = id ? id : name ?? "";
        setSelectedTemp(data);
        setShowPerview(true);
    }

    const handleOpenAppDemo = (job: string) => {
        updateJob(job);
        setShowAppDemo(true);
    }

    const handleOpenSubscription = () => {
        updateJob(job);
        setShowSubscriptionPage(true);
    }

    const handleDownloadApp = () => {
        console.log("handleDownloadApp");
    }

    return (
        <div className='new-job' style={{
            width: '100%',
            height: 'auto',
            padding: '20px',
            border: '1px solid #222647',
            display: 'flex',
            borderRadius: '4px',
            justifyContent: 'space-between'
        }}>
            <div className='' style={{
                width: '25%'
            }}>
                {image ? <img width={"70px"} height={"70px"} src={image} alt={job?.appTitle} /> : <PlaceholderImage width={"204px"} height={"236px"} />}
            </div>
            <div className='' style={{
                width: '70%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 15,
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <h4 style={{
                        fontSize: '20px',
                        color: '#1F1F1F',
                    }}>{job?.appTitle}</h4>
                    <BadgeButton status={job?.jobStatus} />
                </div>
                <div className='job_middle_content' style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15
                }}>
                    <p style={{
                        fontSize: '20px',
                        display: 'flex',
                        justifyItems: 'flex-start',
                        alignItems: 'center',
                        gap: 20
                    }}> <CalenderIcon /> Last Update: {job?.updatedAt && formatDate(job?.updatedAt)}
                    </p>
                    <p className='description' style={{
                        fontSize: '20px'
                    }}>{job?.appDescription}</p>
                    <p className='description' style={{
                        fontSize: '20px'
                    }}>{job?.website}</p>
                </div>
                <div className='job_middle_content' style={{
                    display: 'flex',
                    marginTop: '10px',
                    gap: 10
                }}>
                    {
                        (job?.interimPaymentReceived === true && job?.finalPaymentReceived === false) || job?.finalPaymentReceived === true ?
                            <Button className="newLight" style={{
                                width: '100%'
                            }} onClick={() => handleOpenSubscription()} >Our Subscription Plans</Button>
                            :
                            <Button className="newLight" style={{
                                width: '100%'
                            }} onClick={() => handleOpenTemplate(job?.appThemeId, job?.appTheme)} >Browse Template</Button>
                    }
                    {
                        job?.finalPaymentReceived === true ?
                            <Button className="newLight" style={{
                                width: '100%'
                            }} onClick={() => handleDownloadApp()} >Download App</Button>
                            :
                            <Button className="newLight" style={{
                                width: '100%'
                            }} onClick={() => handleOpenAppDemo(job)} > {job?.interimPaymentReceived === true && job?.finalPaymentReceived === false ? 'Final Payment' : 'App Demo'}</Button>
                    }
                </div>
            </div>
            <div className='' style={{
                width: '5%'
            }}>
                <div className='new-job-right-inner'>
                    <KebabMenu items={kebabMenuItems} style={{
                        marginTop: '-10px'
                    }} />
                </div>
            </div>
        </div>
    )
}
export default NewJobCard;
import React, { useState, useEffect } from 'react';
import { Badge, Space } from 'antd';

interface Props {
    status: "new" | "in_progress" | "completed" | "review";
}

const BadgeButton: React.FC<Props> = ({ status }) => {
    const [badgeStatus, setBadgeStatus] = useState<
        "success" | "processing" | "error" | "default" | "warning" | undefined
    >('success');

    const NewStatus =  status == "in_progress" ? "IN PROGRESS" :  !status ? 'NEW' : status?.toUpperCase() ;

    useEffect(() => {
        // Map status prop to badgeStatus
        switch (status) {
            case "new":
                setBadgeStatus("success");
                break;
            case "in_progress":
                setBadgeStatus("processing");
                break;
            case "completed":
                setBadgeStatus("success");
                break;
            case "review":
                setBadgeStatus("warning");
                break;
            default:
                setBadgeStatus("success");
        }
    }, [status]);

    return (
        <div style={{
            background: '#22264733',
            padding: '4px 10px',
            borderRadius: '4px'
        }}>
            <Space direction="vertical">
                <Badge status={badgeStatus} text={NewStatus} />
            </Space>
        </div>
    );
};

export default BadgeButton;
import './index.sass';

interface Props {
    name: string;
    icon?: React.ReactNode;
    option: { value: string | number; label: string }[];
    title: string;
    onchange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    style?: React.CSSProperties;
    value?: string | number;
}

const SelectOptionDropdown: React.FC<Props> = ({
    name,
    icon,
    option,
    title,
    onchange,
    style,
    value,
}) => {
    return (
        <div
            className='cuDropdown'
            style={{
                position: 'relative'
            }}
        >
            {icon}
            <select
                name={name}
                onChange={onchange}
                style={style}
                defaultValue={value || ''}
            >
                <option
                    value=""
                    disabled
                >{title} </option>
                {option.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
            <div className="select-arrow"></div>
        </div>
    );
};

export default SelectOptionDropdown;
import { useEffect } from 'react';
import CreateAppFooter from 'pages/customer/CustomerPortal/CreateApp/footer';
import FromHeader from 'pages/customer/CustomerPortal/CreateApp/Step/formHeader';
import './index.sass';
import OrderSummery from 'components/common/orderSummery';
import AddCard from 'components/common/paymentCard/add';
import PaymentsCard from 'components/common/paymentCard';
import PaymentStatus from 'components/common/statusPopup';

interface Step1Props {
    formData?: any;
    submitForm?: any;
    prevStep?: any;
    step?: number;
    name?: string;
    activeTab?:any;
    setActiveTab?:any;
    type?:number;
    patymentDone?:boolean;
    setSelectedPaymentCard?:any;
    dispatchForm?: any;
    tabs?:any;
    paymentType?:string;
    title?:any;
    desc?:any;
    descPop?:any;
    titlePop?:any;
    setUpdatePaymentSubmit?:any;
}

const PatmentTabCard = ({ formData,setActiveTab,activeTab, submitForm, prevStep, step, name,patymentDone,setSelectedPaymentCard,tabs,paymentType,title,desc,descPop,titlePop,setUpdatePaymentSubmit }: Step1Props) => {

    const handleTabClick = (index: number) => {
        setActiveTab(index);
        index !== activeTab && setUpdatePaymentSubmit(false);
    };

    useEffect(() => {
       if(patymentDone){
        setActiveTab(2);
       }
    }, [patymentDone]);


    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection:'column'
                }}
            >
                <div className="main_card" style={{
                    width: '100%'
                }}>
                    <FromHeader
                        title={tabs[activeTab]}
                        desc={descPop[activeTab]}
                        step={step}
                        name={name}
                    />
                    <div className="tab-container" style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div className="tab-group" style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            {tabs && tabs.map((tab:any, index:any) => (
                                <div
                                    key={index}
                                    className={`tab ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index)}
                                    aria-selected={activeTab === index}
                                    style={{ 
                                        background: activeTab === index ? '#222647' : '#fff',
                                        color: activeTab === index ? '#fff' : '#222647',
                                        padding: '10px 0px',
                                        border:'1px solid #222647',
                                        width:'100%',
                                        maxWidth: '33%',
                                        display:'flex',
                                        justifyContent:'center'
                                     }} // Apply color here
                                >
                                    <span className="tab-label" style={{
                                        fontSize:'20px',
                                        fontWeight:'500'
                                     }}>{tab}</span>
                                </div>
                            ))}
                        </div>
             
                        <div className="tab-content">
                            {activeTab === 0 && <OrderSummery formData={formData} />}
                            {activeTab === 1 ? <PaymentsCard setActiveTab={setActiveTab} setSelectedPaymentCard={setSelectedPaymentCard} /> : activeTab === 3 && <AddCard stepType={activeTab} setActiveTab={setActiveTab} setSelectedPaymentCard={setSelectedPaymentCard} />}
                            {activeTab === 2 && <PaymentStatus title={title} desc={desc} />}
                        </div>
                    </div>
                </div>
                { paymentType === "deposit" && step && <CreateAppFooter nextStep={submitForm} prevStep={prevStep} step={step} price={formData?.price} type={activeTab} setActiveTab={setActiveTab}/>}
            </div>
        </div>
    );
};

export default PatmentTabCard;
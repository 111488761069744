import CircleCheckSvg from "pages/customer/CustomerPortal/utils/SVG/circleCheck";

interface Props {
    title?: string;
    desc?: string;
    type?: string;
}

const StatusPopup = ({ title, desc, type }: Props) => {
    const containerStyle: any = {
        height: !type && "100vh",
        marginTop: "25px",
    };

    return (
        <div style={containerStyle}>
            <div style={{
                display: 'flex',
                height: '50vh',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: !type ? '#2226470D' : '#FEFEFE'
            }}>
                <div style={{
                    display: 'flex',
                    maxWidth: '586px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px'
                }}>
                    <CircleCheckSvg />
                    <p style={{
                        fontSize: '20px',
                        color: '#222647',
                        fontWeight: '500'
                    }}>{title}</p>
                    <p style={{
                        fontSize: '16px',
                        color: '#929292',
                        textAlign: 'center'
                    }}>{desc}</p>
                </div>
            </div>
        </div>
    )
}
export default StatusPopup;

import React from "react";

const CircleCheckSvg: React.FC = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="48.5" stroke="#BFBFBF" strokeWidth="3" />
            <path d="M67.6667 38L43.8333 61.8333L33 51" stroke="#BFBFBF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default CircleCheckSvg;

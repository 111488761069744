interface HeadProps {
    title: String;
    desc?: String;
    step?:number;
    name?: String;
}

const FromHeader = ({ title, desc,step,name }: HeadProps) => {
    return (
        <div className="main_card"  style={{
            width: step == 0 ? '95%' : '100%',
            margin:'0 auto'
          }}>
           {step && step > 0 ? <div className="" style={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <p style={{color:'#908C8C'}}> {step}/4 {name}</p>
            </div>
            : ''
            }
            <div className="header_new" style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 30,
                margin: name ? '20px 40px 40px' : '20px 0px 40px',
                flexDirection: 'row'
            }}>
                <div className="card_top_heading"
                    style={{
                        width: '100%'
                    }}
                >
                    <h4 style={{
                        fontSize: '30px',
                        fontWeight: 'bold'
                    }} >
                        {title}
                    </h4>
                </div>
                <div className="" style={{
                    width: '100%',
                    fontSize: '20px'
                }} >
                    {desc}
                </div>
            </div>
        </div>
    )
}
export default FromHeader;
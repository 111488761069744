import React, { useState, useContext, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import CardForm from './form';
import { Elements } from '@stripe/react-stripe-js'
import "./index.sass"
import { stripePromise } from '../../../utils/constants'

const AddCardForm = () => {
    return (
        <Elements stripe={stripePromise}>
            <CardForm formid={``} type={`add`} stepType={0} setActiveTab={0}/>
        </Elements>
    );
}
export default AddCardForm;
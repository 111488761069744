interface Props {
    color?: any;
}

const GreenTickSvg = ({ color }: Props) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="11" fill={ !color ? "#319F43" : "transparent"} />
            <path d="M5.75 11.75L9.25 15.25L16.25 7.75" stroke={color ? color : "white"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default GreenTickSvg;

import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons'; // Import the PlusOutlined icon

const ColorStyle: React.FC = () => {
    const [selectedColor, setSelectedColor] = useState<string | null>("#F4A3B1"); // State to track the selected color

    const handleColorClick = (color: string) => {
        setSelectedColor(color); // Set the selected color
    };

    return (
        <div style={{ margin: "20px 0" }}>
            <p style={{ color: "#222647", marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
                Color Style
            </p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px', // Fixed gap with 'px'
                }}
            >
                {["#F4A3B1", "#3D9EFF", "#47927E", "#5F5CFF", "#B5AAA6"].map((color) => (
                    <p
                        key={color}
                        onClick={() => handleColorClick(color)} // Handle color click
                        style={{
                            backgroundColor: color,
                            borderRadius: '50%',
                            height: '30px', // Fixed height
                            width: '30px', // Fixed width
                            border: selectedColor === color ? '2px solid #222647' : '', // Highlight border
                            cursor: 'pointer',
                        }}
                    ></p>
                ))}
                <p
                    style={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '50%',
                        display: 'flex',
                        border: selectedColor === null ? '2px solid #222647' : '1px solid #222647',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <PlusOutlined style={{ fontSize: '18px', color: '#222647' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </p>
            </div>
        </div>
    );
};

export default ColorStyle;

import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper/modules'; 
import 'swiper/swiper-bundle.css'; // Import Swiper CSS
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './product-image-slider.sass'

interface ProductImagesSliderProps {
    images: any[];
  }

const ProductImagesSlider = ({ images }: ProductImagesSliderProps) => {
    const [activeThumb, setActiveThumb] = useState < any | null > (null);

    return <>
        <Swiper
            loop={true}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation, Thumbs]}
            grabCursor={true}
            thumbs={{ swiper: activeThumb }}
            className='product-images-slider'
            style={{
                display:'flex',
                justifyContent:"center"
            }}
        >
            {
                images.map((item, index) => (
                    <SwiperSlide key={index}>
                        {item}
                    </SwiperSlide>
                ))
            }
        </Swiper>
        <Swiper
            onSwiper={setActiveThumb}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            modules={[Navigation, Thumbs]}
            className='product-images-slider-thumbs'
            style={{
                width:'100%'
            }}
        >
            {
                images.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="product-images-slider-thumbs-wrapper">
                           {item}
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    </>
}

export default ProductImagesSlider
import React, { useState, useContext, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import CardForm from './form';
import {  Elements } from '@stripe/react-stripe-js'
import { stripePromise } from '../../../utils/constants'
import { useParams } from 'react-router-dom';

const EditCardForm = () => {
    const { Id } = useParams();
    return (
        <Elements stripe={stripePromise}>
            <CardForm formid={Id} type={`edit`} stepType={0} setActiveTab={0} />
        </Elements>
    );
}

export default EditCardForm; 
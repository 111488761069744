import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CreateAppState {
  job: {}
}

const initialState: CreateAppState = {
  job: ''
};

export const counterSlice = createSlice({
  name: 'CREATEAPPSLICE',
  initialState,
  reducers: {
    setAppJob: (state, action: PayloadAction<any>) => {
      state.job = action.payload
    }
  },
})

export const { setAppJob } = counterSlice.actions
export default counterSlice.reducer
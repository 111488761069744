import React, { Dispatch } from "react";

// Action Types
export const UPDATE_FORM = "UPDATE_FORM";
export const RESET_FORM = "RESET_FORM";

// State Type Definition
export type FormState = {
  [key: string]: {
    value: string | File | null;
    hasError: boolean;
    error: string;
    touched: boolean;
  };
};

// Action Type Definition
export type FormAction = {
  type: typeof UPDATE_FORM | typeof RESET_FORM;
  data?: {
    name: string;
    value: string | File | null;
    hasError: boolean;
    error: string;
    touched: boolean;
    isFormValid: boolean;
  };
};

// Form Validation Logic
export const validateInput = (name: string, value: string | File | null, formState: FormState) => {
  let hasError = false;
  let error = "";

  switch (name) {
    case "feedback":
      if (typeof value === "string" && value.trim() === "") {
        hasError = true;
        error = "Your Feedback can't be empty";
      }
      break;
    case "attachment":
      if (value === null || !value) {
        hasError = true;
        error = "File is required";
      } else if (value instanceof File && value.size > 5 * 1024 * 1024) { // Limit to 5MB
        hasError = true;
        error = "File size exceeds the 5MB limit";
      }
      break;
    default:
      break;
  }

  return { hasError, error };
};

// Action for Input Change
export const onInputChange = (
  name: string,
  value: string | File | null,
  dispatch: Dispatch<FormAction>,
  formState: FormState
) => {
  const { hasError, error } = validateInput(name, value, formState);
  let isFormValid = true;

  for (const key in formState) {
    const item = formState[key];
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      isFormValid = false;
      break;
    }
  }

  dispatch({
    type: UPDATE_FORM,
    data: { name, value, hasError, error, touched: false, isFormValid },
  });
};

// Action for Input Focus Out
export const onFocusOut = (
  name: string,
  value: string | File | null,
  dispatch: Dispatch<FormAction>,
  formState: FormState
) => {
  const { hasError, error } = validateInput(name, value, formState);
  let isFormValid = true;

  for (const key in formState) {
    const item = formState[key];
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      isFormValid = false;
      break;
    }
  }

  dispatch({
    type: UPDATE_FORM,
    data: { name, value, hasError, error, touched: true, isFormValid },
  });
};

// Action to Reset Form
export const resetForm = (): FormAction => {
    return {
      type: RESET_FORM,  // Correct action type
    };
  };
  
// Initial Form State
export const initialState: FormState = {
  feedback: { value: "", touched: false, hasError: false, error: "" },
  attachment: { value: null, touched: false, hasError: false, error: "" },
};

// Form Reducer
export const formReducer = (state: FormState = initialState, action: FormAction): FormState => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        [action.data!.name]: {
          ...state[action.data!.name],
          ...action.data,
        },
      };
    case RESET_FORM:
      return initialState; // Reset the form to initial state
    default:
      return state;
  }
};

import React, { useCallback, useContext, useEffect, useState } from 'react';
import CreateAppFooter from '../../footer';
import FromHeader from '../formHeader';
import { Card } from 'antd';
import PlaceholderSvg from 'pages/customer/CustomerPortal/utils/SVG/placeholder';
import './index.sass';
import { AuthContext } from 'context/AuthContext';
import client from 'feathers-client';
import { SERVICES } from 'utils/constants';
import SelectOptionDropdown from 'components/common/dropdown';
import SearchBarInput from 'components/common/searchBar';
import FilterSvg from 'pages/customer/CustomerPortal/utils/SVG/filtter';
import { onInputChange } from '../../../../../../components/lib/validation/createApp';
import PreviewTemplateCard from 'components/common/prevTemplate';

interface Step1Props {
    formData: any;
    nextStep: () => void;
    prevStep: () => void;
    step: number;
    dispatchForm: any;
    name: string;
}

interface Template {
    "icon": string;
    "type": string;
    "description": string;
    "deposit": number;
    "yearlyDiscount": number;
    "monthlySubscription": number;
    "finalPayment": number;
    "added": number;
    "updatedAt": number;
    "interim": number;
    "id": string;
}


const Step2 = ({ formData, dispatchForm, nextStep, step, name, prevStep }: Step1Props) => {
    const [selectedCard, setSelectedCard] = useState<number | null>(null);
    const [selectedTemp, setSelectedTemp] = useState<string | null>(null);
    const auth = useContext(AuthContext)
    const [appTemplates, setAppTemplates] = useState<Template[]>([])
    const [appTemplatesCount, setAppTemplatesCount] = useState<number>(0)
    const [showPerview, setShowPerview] = useState<boolean>(false)
    const [sortOption, setSortOption] = useState<String | number>(1);
    const [filterOption, setFilterOption] = useState('');

    const fetchAppTemplates = useCallback(async () => {
        if (auth) {
            let query = {
                $sort: {
                    added: 1
                }
            };
            const appTemplates = await client.service(SERVICES.APP_TEMPLATES).find({ query });

            if (Array.isArray(appTemplates?.data)) {
                setAppTemplates(appTemplates.data);
                setAppTemplatesCount(appTemplates.total || 0)
            }
        }

    }, [auth]);

    // Fetch app templates on component mount with initial effect
    useEffect(() => {
        fetchAppTemplates();
    }, [fetchAppTemplates]);

    const fetchAppTemplatesBySearch = async (value?: string | number, type?: string) => {
        try {
            let appTemplates;
            const trimmedValue = typeof value === 'string' ? value.trim() : value;

            if (type === 'search' && trimmedValue) {
                const query = { type: trimmedValue };
                appTemplates = await client.service(SERVICES.APP_TEMPLATES).find({ query });
            } else if (type === 'sort' && (trimmedValue === 1 || trimmedValue === -1)) {
                const query = {
                    $sort: { added: trimmedValue }
                };
                appTemplates = await client.service(SERVICES.APP_TEMPLATES).find({ query });
            } else {
                if (trimmedValue == "") {
                    const query = {
                        $sort: { added: 1 }
                    };
                    appTemplates = await client.service(SERVICES.APP_TEMPLATES).find({ query });
                }
            }

            if (Array.isArray(appTemplates?.data)) {
                setAppTemplates(appTemplates.data);
                setAppTemplatesCount(appTemplates.total || 0);
            }
        } catch (error) {
            console.error('Error fetching app templates:', error);
        }
    };

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = Number(event.target.value);
        setSortOption(selectedOption);
        fetchAppTemplatesBySearch(selectedOption, 'sort');
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        fetchAppTemplatesBySearch(searchValue, 'search');
    };


    const sortOptions = [
        { value: 1, label: "ASC" },
        { value: -1, label: "DESC" }
    ];

    const filterOptions = [
        { value: "name", label: "Name" },
        { value: "price", label: "Price" },
        { value: "type", label: "Type" }
    ];

    const handleFilterChange = (selectedOption: any) => {
        console.log("handleFilterChange", selectedOption);
        // setFilterOption(selectedOption);
    };
    const handelPreview = (id: string) => {
        setShowPerview(!showPerview);
        setSelectedTemp(id);
    };

    const handleCardClick = (index: any) => {
        setSelectedCard(index);
    };
  
    return (
        <div style={{ width: '100%' }}>
            {showPerview ?
                <PreviewTemplateCard step={step} name={name} nextStep={nextStep} prevStep={prevStep} setShowPerview={setShowPerview} selectedTemp={selectedTemp} />
                : <>
                    <div
                        style={{
                            height: '80vh',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div className="main_card">
                            <FromHeader
                                title={"Select Your App Template"}
                                desc={"Browse through our library of professionally crafted app templates, designed to streamline your development process."}
                                step={step}
                                name={name}
                            />
                            <div className='' style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <SearchBarInput onchange={handleSearchChange} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                }}>
                                    <SelectOptionDropdown
                                        name={'filter'}
                                        option={filterOptions}
                                        title={'Filter'}
                                        icon={<FilterSvg />}
                                        onchange={handleFilterChange}
                                        style={{
                                            width: '200px',
                                            paddingLeft: '40px'
                                        }}
                                        value={``}
                                    />
                                    <SelectOptionDropdown
                                        name={'sort'}
                                        option={sortOptions}
                                        title={'Sort'}
                                        onchange={handleSortChange}
                                        style={{
                                            maxWidth: '300px',
                                        }}
                                        value={``}
                                    />
                                </div>
                            </div>
                            <div style={{ margin: '70px 0px 0px' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    gap: '20px',
                                    width: '100%'
                                }}>
                                    {appTemplates &&
                                        appTemplates.map((item, index) => (
                                            <Card
                                                key={index}
                                                onClick={() => {
                                                    handleCardClick(index);
                                                    onInputChange("template", item?.type, dispatchForm, formData);
                                                    onInputChange("template_id", item?.id, dispatchForm, formData);
                                                    onInputChange("price", String(item?.deposit), dispatchForm, formData);
                                                }}
                                                style={{
                                                    width: appTemplatesCount && appTemplatesCount == 1 ? '33%' : appTemplatesCount > 0 ? '25%' : '50%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    gap: '0',

                                                }}
                                            >
                                                <div className="cardStyles" style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '0',
                                                    border: selectedCard === index || formData?.fields?.template_id?.value == item?.id
                                                        ? '1px solid #2CBBE8'
                                                        : '1px solid transparent',
                                                }}>
                                                    <div style={{
                                                        width: '100%',
                                                        background: '#ebebeb',
                                                    }}>
                                                        <PlaceholderSvg width={'345'} height={'198'} />
                                                    </div>
                                                    <div style={{
                                                        background: '#F6F6F6',
                                                        width: '100%',
                                                        padding: '20px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        minHeight: '229px'
                                                    }}>
                                                        <div className="steps-heading">{item?.type}</div>
                                                        <div>{item?.description}</div>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <button
                                                                type='button'
                                                                className=''
                                                                onClick={() => handelPreview(item?.id)}
                                                                style={{
                                                                    padding: '10px 20px'
                                                                }}>
                                                                Preview
                                                            </button>
                                                            <button
                                                                type='button'
                                                                onClick={() => {
                                                                    onInputChange("template", item?.type, dispatchForm, formData);
                                                                    onInputChange("template_id", item?.id, dispatchForm, formData);
                                                                    onInputChange("price", String(item?.deposit), dispatchForm, formData);
                                                                }}
                                                                style={{
                                                                    padding: '10px 20px'
                                                                }}>
                                                                Choose Template
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                </div>
                                {formData?.fields?.template?.touched && formData?.fields?.template?.hasError && (
                                    <div className="error">{formData?.fields?.template?.error}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <CreateAppFooter nextStep={nextStep} step={step} prevStep={prevStep} price={"0"} type={0} setActiveTab={0} />
                </>
            }
        </div >
    );
};

export default Step2;
import { InfoCircleOutlined } from '@ant-design/icons'

interface Props {
    title: string;
    price: number | string;
    type: boolean;
}

const SmallCardInfo = ({ title, price, type }: Props) => {

    return (
        <div style={{
            display: 'flex',
            padding:"10px",
            justifyContent: "space-between",
            justifyItems:"center",
            flexDirection: type ? "column" : "row",
            width: "100%",
            background: "#f4f4f6",
            borderRadius: "4px",
            gap:7,
            minHeight:"40px"
        }}>
            <div style={{
                display: 'flex',
                justifyContent: "space-between"
            }}>
                <p className="card__title">{title}</p>
                {type && <InfoCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
            </div>
            {price && <div className="card__content" style={{ color: "#222647", fontWeight: 'bold' }}>${price}</div>}
        </div>
    )
}
export default SmallCardInfo;
import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import createAppReducer from './slices/createAppSlice';

export const store = configureStore({
  reducer: {
    userSlice: userReducer,  // Your other reducers
    createAppReducer: createAppReducer,  // Ensure the correct key here
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the serializable check
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
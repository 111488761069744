import CreateAppFooter from "pages/customer/CustomerPortal/CreateApp/footer";
import FromHeader from "pages/customer/CustomerPortal/CreateApp/Step/formHeader";
import { useCallback, useEffect, useState } from "react";
import SmallCardInfo from "../smallCardInfo";
import ProductImagesSlider from "../product-images-slider";
import PlaceholderSvg from 'pages/customer/CustomerPortal/utils/SVG/placeholder';
import AppPlatform from "./appPlatform";
import AppMode from "./appMode";
import ColorStyle from "./colorStyle";
import NoJobData from "components/NoJobData";
import CalendarSvg from "components/SvgIcons/calender";
import { fetchAppTemplates } from "pages/customer/CustomerPortal/utils/action";

interface Step1Props {
    step?: number | any;
    name?: string | any;
    nextStep?: () => void;
    prevStep?: () => void;
    setShowPerview?: any | any;
    selectedTemp?: string | null;
}

const PreviewTemplateCard = ({ step, name, prevStep, nextStep, setShowPerview, selectedTemp }: Step1Props) => {

    const [appTemplates, setAppTemplates] = useState<any>();

    const fetchAppTemplate = useCallback(async () => {
        if(selectedTemp){
            const template = await fetchAppTemplates(selectedTemp);
            setAppTemplates(template);
          }
    }, [selectedTemp])

    useEffect(() => {
        fetchAppTemplate();
    }, [fetchAppTemplate]);

    const productImages = [
        <PlaceholderSvg width={"100%"} height={"100%"} />,
        <PlaceholderSvg width={"100%"} height={"100%"} />,
        <PlaceholderSvg width={"100%"} height={"100%"} />,
        <PlaceholderSvg width={"100%"} height={"100%"} />
    ];

    return (
        appTemplates ?
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        height: '80vh',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div className="main_card">
                        <FromHeader
                            title={"Template Name"}
                            desc={"Choose from expertly designed templates to kickstart your app effortlessly."}
                            step={step}
                            name={name}
                        />
                        <div style={{
                            display: 'flex',
                            justifyContent: "center",
                            gap: "25px"
                        }}>
                            <div style={{
                                width: "50%"
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: 20
                                }}>
                                    <div style={{
                                        width: '500px',
                                        backgroundColor: '#fff',
                                        padding: '20px'
                                    }}>
                                        <ProductImagesSlider images={productImages} />
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "50%"
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 10,
                                    marginBottom: "20px",

                                }}>
                                    <h4 style={{
                                        fontSize: "24px",
                                        fontWeight: "bold"
                                    }}>
                                        {appTemplates?.type}</h4>
                                    <p>{appTemplates?.description}</p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    marginBottom: "20px",
                                    gap: 20
                                }}>
                                    <SmallCardInfo title={"Deposit"} price={appTemplates?.deposit} type={true} />
                                    <SmallCardInfo title={"Interim"} price={appTemplates?.interim} type={true} />
                                    <SmallCardInfo title={"Final"} price={appTemplates?.finalPayment} type={true} />
                                </div>

                                <div style={{
                                    display: 'flex',
                                    justifyContent: "space-between"
                                }}>
                                    <SmallCardInfo title={"Total Payment"} price={parseInt(appTemplates?.deposit) + parseInt(appTemplates?.interim) + parseInt(appTemplates?.finalPayment)} type={false} />
                                </div>
                                <AppMode />
                                <AppPlatform />
                                <ColorStyle />
                            </div>
                        </div>
                    </div>
                </div>

                {nextStep && prevStep && <CreateAppFooter nextStep={nextStep} prevBtn={true} setShowPerview={setShowPerview} step={step} prevStep={prevStep} price={"0"} type={0} setActiveTab={0} />}
            </div>
            :
            <NoJobData
                icon={<CalendarSvg height={`60px`} width={`60px`} fill={`#00314D`} />}
                title="No app templates were found. Please go back."
                description=""
            />
    )
}
export default PreviewTemplateCard;
import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./index.sass";
import { AuthContext } from 'context/AuthContext'
import { LINKS, getJobDetailsUrl } from 'routes/constants'

import NoData from 'components/NoData/NoData'
import NoJobData from 'components/NoJobData'
import Button from 'components/forms/Button/Button'
import questionIcon from 'assets/questionMark.svg';
import jobsIcon from 'assets/jobs.svg'
import infoIcon from 'assets/info-circle.svg'
import checklistIcon from 'assets/list-check.svg'
import JobsListing from '../../jobs/JobsListing'
import CalendarSvg from 'components/SvgIcons/calender';

import '../../jobs/jobs.sass'
import {
  createTask,
  deleteJob,
  jobFetchingSearch,
  jobFetchingSort,
  jobProposalRevoke,
  jobProposalSorting,
  pinJobs,
  unpinJobs,
} from '../../jobs/utils'
import axios, { AxiosRequestConfig } from 'axios';
import client from 'feathers-client'
import { DEFAULT_AVATAR, SERVICES, URL_PATH } from 'utils/constants'
import KebabMenu from 'components/KebabMenu/KebabMenu';
import DeleteModal from 'components/ActionModal/DeleteModal';
import ModalPreviewJobTemplate from 'components/ActionModal/components/ModalPreviewJobTemplate';
import BaseModal from 'components/ActionModal/BaseModal';
import ShowInfoModal from 'pages/jobs/components/ShowInfoModal';
import deleteIcon from 'assets/delete.svg'
import { toast } from 'react-toastify';
import TextField from 'components/forms/TextField/TextField';
import PageHeader from 'components/Header/PageHeader/PageHeader';
import axiosInstance from 'utils/axios';

import Card from '../payments/Card';
import { SavedCard } from 'types/card';
import { getImageForBrand, getExpiryStatus } from 'utils/helpers'
import Loader from 'components/Loader/Loader';
import MyAppsFooter from '../footer';
import NewJobCard from 'components/common/appCard';
import FromHeader from './CreateApp/Step/formHeader';
import SearchBarInput from 'components/common/searchBar';
import SelectOptionDropdown from 'components/common/dropdown';
import FilterSvg from './utils/SVG/filtter';
import PreviewTemplateCard from 'components/common/prevTemplate';
import AppDemoCard from 'components/common/appDemo';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import PatmentTabCard from 'components/common/paymentTabCard';
import { fetchAppTemplates, paymentHandler as createAppPaymentHandler, handelUpdateAppAfterPayment } from './utils/action';
import AppSubscriptionCard from 'components/common/appSubscription';

export interface IJobItem {
  id: string
  name: string
  customerName: string
  customerEmail: string
  customerBusinessName: string
  invited: number
  invitedEmail: string
  pinned?: boolean
  appIndustry?: string
  appDescription?: string
  suggestedAppTitle?: string
  requestedAppName?: boolean
  developedFor?: 'ios' | 'android' | 'ios+android'
  appTheme?: string
  appPrice?: 'free' | 'paid'
  appUpdates?: 'none' | 'weekly'
  appRequirements?: string
  attachments?: any[]
  submitted?: number // timestamp customer submitted the request
  isSubmitted: boolean
  revoked?: number // timestamp job revoked
  revokedBy?: string // id of user who revoked proposal
  activity?: Array<{
    added: number
    addedBy: string
    comment: string
    attachments: any[]
  }>
  jobStatus: string | undefined
}

interface IJobsProps extends HTMLAttributes<HTMLDivElement> { }

interface PaymentRequest {
  amount: number;
  currency: string;
  email?: string;
  user_Id?: string;
  jobId: any;
  paymentType: string;
  description: string;
  paymentMethodId?: string; // Optional property
}

const CustomerDashboard = ({ ...props }: IJobsProps) => {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const isTemp = auth?.user?.permissions?.includes("temp");
  const [loading, setLoading] = useState<boolean>(true);
  const [template, setTemplate] = useState<any>({});
  const [jobTasksTemplates, setJobTasksTemplates] = useState<any>({});
  const [showBusinessNameModal, setShowBusinessNameModal] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>("");
  const [showBusinessError, setShowBusinessError] = useState<boolean>(false);
  const [savedCards, setSavedCards] = useState<SavedCard[]>([]);
  const [showPaymentModal, setShowPaymentModal] = useState<any>(false)
  const [selectedPaymentCard, setSelectedPaymentCard] = useState<any>(null)
  const [selectedJobDataPayment, setSelectedJobDataPayment] = useState<any>(null)
  const [paymentTemplate, setPaymentTemplate] = useState<any>({});
  const [activeTemplate, setActiveTemplate] = useState<any>({});
  const [paymentLoader, setPaymentLoader] = useState<boolean>(false);
  const [selectedTemp, setSelectedTemp] = useState<string | null>(null);
  const [showPerview, setShowPerview] = useState<boolean>(false)
  const [showAppDemo, setShowAppDemo] = useState<boolean>(false)
  const [showAppDemoJob, setShowAppDemoJob] = useState<string | null>(null);
  const [showPaymentPage, setShowPaymentPage] = useState<boolean>(false);
  const [appTemplatePayment, setAppTemplatePayment] = useState({});
  const [paymentDetail, setPaymentDetail] = useState<any>({});
  const [activeTab, setActiveTab] = useState<number>(0);
  const [step, setStep] = useState<number>(0);
  const [updatePaymentSubmit, setUpdatePaymentSubmit] = useState<boolean>(false);
  const [patymentDone, setPatymentDone] = useState(false);
  const [showSubscriptionPage, setShowSubscriptionPage] = useState<boolean>(false);

  /** get data from radux */
  const UserJob: any = useSelector((state: RootState) => state.createAppReducer.job);
  const GetAllTemplates = async (templateData: any) => {
    const template = await fetchAppTemplates(templateData);
    setAppTemplatePayment(template);
  }

  useEffect(() => {

    if (UserJob) {
      /** set selected template */
      if (UserJob?.appThemeId || UserJob?.appTheme) {
        const templateData = UserJob?.appThemeId || UserJob?.appTheme;
        GetAllTemplates(templateData);
      }

      const paymentTypes = UserJob?.interimPaymentReceived == false ? 'Interim Payment' : 'Final Payment';
      const tabs = ["Order Summary", paymentTypes, "Payment Status"];
      const paymentType = UserJob?.interimPaymentReceived == false ? 'interim' : 'final';
      const title = "Deposit received!";
      const desc = "Your deposit has been successfully processed. You can now proceed with the next steps to bring your app to life.";
      const descPop = ["Review the details of your order, before proceeding to checkout.", "Make a partial payment to keep your project on track. Secure progress while maintaining flexibility.", "Track the status of your payment and stay informed about your transaction progress."];
      setPaymentDetail({
        tabs,
        paymentType,
        title,
        desc,
        descPop,
        appTemplatePayment
      })
    }

  }, [UserJob]);


  const fetchPaymentMethods = async () => {
    try {
      setLoading(true);
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}get-payment-methods`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          user_Id: auth?.user?._id,
          name: auth?.user?.name,
          email: auth?.user?.email
        }),
      };
      const response = await axios.request(config);
      if (response && (response.status === 200 || response.status === 201)) {
        setSavedCards(response?.data?.data);
        setSelectedPaymentCard(response?.data?.data[0] || [])
      }
    } catch (error) {
      console.log("Failed to fetch payment methods", error);

      // toast.error('Failed to fetch payment methods', {
      //   position: toast.POSITION.BOTTOM_CENTER,
      // });
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    if (auth) {
      fetchPaymentMethods();
    }
  }, [auth]);

  useEffect(() => {
    const fetchTemplateTimer = setTimeout(async () => {
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "active_job"
        }),
      };

      try {
        const templateResult = await axios.request(configE);
        if (templateResult.data.status === 200) {
          setActiveTemplate(templateResult.data.template.data[0])
        } else {
          console.log("Template not found !!!")
        }
      }
      catch (err: any) {
        console.log("Something went wrong!!!", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [auth])

  const paymentHandler = async (paymentType: any) => {
    setPaymentLoader(true)
    const paymentRequest: PaymentRequest = {
      amount: (paymentType === "deposit" ? selectedJobDataPayment.deposit : selectedJobDataPayment.finalPayment) * 100, // Amount in cents (Stripe uses cents)
      currency: "usd", // Desired currency code
      email: auth?.user?.email,
      user_Id: auth?.user?._id,
      jobId: selectedJobDataPayment.id,
      paymentType: paymentType.toLocaleUpperCase(),
      description: "Your payment description here", // Add a description for the payment
    };

    if (selectedPaymentCard) {
      paymentRequest.paymentMethodId = selectedPaymentCard.id;
    }
    const configE: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}create-payment`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(paymentRequest),
    };

    try {
      // setLoading(true);
      const apiResult = await axios.request(configE);
      if (apiResult.status === 200 || apiResult.status === 201) {
        if (paymentType === "deposit") {
          isJobActiveHandler(selectedJobDataPayment.id)
        } else {
          fullPaymentHandler(selectedJobDataPayment.id)
        }
        // navigate(LINKS.PAYMENT_SUCCESS);
      } else {
        setPaymentLoader(false)
        navigate(LINKS.PAYMENT_FAILED);
      }
    }
    catch (err: any) {
      setPaymentLoader(false)
      console.log("Something went wrong!!!", err.message)
      navigate(LINKS.PAYMENT_FAILED);
    } finally {
      // setLoading(false);
    }
  }

  const paymentReceivedEmailHandler = useCallback(
    async (paymentType: any) => {
      let INVITE: any;
      let customer_name = auth?.user?.name
      let customerFirstName = customer_name?.split(" ")?.shift();
      INVITE = paymentTemplate.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Payment]", ` $${paymentType === "final" ? selectedJobDataPayment?.finalPayment?.toFixed(2) : selectedJobDataPayment?.deposit?.toFixed(2)}`).replaceAll("[Payment Type]", paymentType);

      try {
        const emailObj = {
          to: auth?.user?.email,
          subject: paymentTemplate.subject,
          text: INVITE,
        }
        const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
        // toast.success(' successfully', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
        // setResendEmail({
        //   status:true,
        //   email:staff.email
        // })
      } catch (err: any) {
        console.log(err?.message)
        // toast.success('Something went wrong!!!', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
      }

      // console.log(INVITE)
    }, [auth, paymentTemplate, selectedJobDataPayment]
  )
  const activeJobEmailHandler = useCallback(
    async () => {
      let INVITE: any;
      let customer_name = auth?.user?.name
      let customerFirstName = customer_name?.split(" ")?.shift();


      INVITE = activeTemplate.body.replaceAll("[Customer Name]", customerFirstName || customer_name);

      try {
        const emailObj = {
          to: auth?.user?.email,
          subject: activeTemplate.subject,
          text: INVITE,
        }
        const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
        // toast.success(' successfully', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
        // setResendEmail({
        //   status:true,
        //   email:staff.email
        // })
      } catch (err: any) {
        console.log(err?.message)
        // toast.success('Something went wrong!!!', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
      }

      // console.log(INVITE)
    }, [auth, activeTemplate]
  )

  const renderedCards = () => {
    return (

      <div>
        <div className="cards-list" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '40px' }}>
          {loading && (
            <div className="loader"></div>
          )}
          {savedCards?.length ? savedCards.map((card, index) => (
            // card
            <div key={index} style={{ width: '180px', height: '180px' }}>
              <Card
                key={index}
                title={card.card.brand === 'paypal' ? card.card.brand : `Ending in ${card.card.last4}`}
                expiryDate={card.card.exp_month + '/' + card.card.exp_year}
                image={getImageForBrand(card.card.brand)}
                showStatus={getExpiryStatus(card.card.exp_month, card.card.exp_year)}
                checkbox={true}
                checked={selectedPaymentCard?.card?.last4 === card.card.last4} // Pass checked prop based on selectedCardIndex
                onClick={() => {
                  setSelectedPaymentCard(card)
                }} // Pass onClick function
              // paymentMethodId={paymentMethodId}
              />
            </div>
          ))
            :
            <p>No cards saved yet.</p>
          }
        </div>
      </div>
    )
  }

  const paymentModalJSX = (
    <div className='payment-modal-main'>
      <div className=''>
        <img src="./assets/logo-black-text.png" alt="" />
      </div>
      <div className="credit_card_heading" style={{
        marginTop: '20px',
      }}>
        <h4 style=
          {{
            fontWeight: '600',
            color: '#00314D',
            marginBottom: '10px',
            fontSize: '40px',
            lineHeight: '48px',
          }}>Make Payment</h4>
        <p style={{
          marginBottom: '20px',
          color: '#60747E',
          fontSize: '16px',
          lineHeight: '19.2px',
          fontWeight: '400',
        }}
        >Manage your credit cards and payment options.</p>
      </div>
      <div className="payment_method">
        {renderedCards()}
      </div>
      <div
        className='payment_logo'
        style={{
          display: 'flex',
          gap: '0px 10px',
          marginTop: '50px',
        }}
      >
        <img src="assets/cardlogo.png" />
        <img src="assets/visacardlogo.png" />
        <img src="/assets/paypallogo.png" />
        <img src="/assets/discoverlogo.png" />
        <img src="/assets/amricanlogo.png" />
      </div>
    </div>
  )

  const paymentModalActionsJSX = (
    <>
      <button className="cancel_button" style={{ cursor: "pointer" }} onClick={() => setShowPaymentModal(false)}>Cancel</button>
      {selectedJobDataPayment &&
        <button
          disabled={paymentLoader}
          style={{ minWidth: paymentLoader ? 180 : "fit-content" }}
          className="primary button"
          onClick={() => {
            console.log("savedCards", savedCards);

            if (!savedCards?.length) {
              navigate(LINKS.CUSTOMER_PAYMENTS)
            } else {
              paymentHandler(selectedJobDataPayment?.depositReceived ? "final" : "deposit")
            }
          }}
        >{savedCards?.length ?
          paymentLoader ? <Loader className='white-color' /> : `Pay Now $${selectedJobDataPayment?.depositReceived ? selectedJobDataPayment?.finalPayment.toFixed(2) : selectedJobDataPayment?.deposit.toFixed(2)}`
          :
          "Add Card"
          }
        </button>}
    </>
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [jobs, setJobs] = useState<any[]>([])
  const fetchJobs = useCallback(async () => {
    if (auth) {
      const Useremail = auth?.user?.email;
      const jobData = await client.service(SERVICES.JOBS).find({
        query: {
          customerEmail: Useremail,
          $sort: { updatedAt: -1 }
        },
      })
      if (Array.isArray(jobData?.data)) {
        setJobs(jobData.data)
      }
    }
    setLoading(false)
  }, [auth])

  useEffect(() => {
    fetchJobs()
  }, [fetchJobs])

  useEffect(() => {
    if (auth !== null && !!auth?.user?.businessName === false) {
      setShowBusinessNameModal(true)
    }
  }, [auth])

  useEffect(() => {
    const fetchTemplateTimer = setTimeout(async () => {
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "building_job"
        }),
      };


      try {
        const templateResult = await axios.request(configE);
        if (templateResult.data.status === 200) {
          setTemplate(templateResult.data.template.data[0])
        } else {
          console.log("Template not found !!!")
        }
      }
      catch (err: any) {
        console.log("Something went wrong!!!", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [auth])

  useEffect(() => {
    const fetchTemplateTimer = setTimeout(async () => {
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "payment_received"
        }),
      };

      try {
        const templateResult = await axios.request(configE);
        if (templateResult.data.status === 200) {
          setPaymentTemplate(templateResult.data.template.data[0])
        } else {
          console.log("Template not found !!!")
        }
      }
      catch (err: any) {
        console.log("Something went wrong!!!", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [auth])

  useEffect(() => {
    const fetchTemplateTimer = setTimeout(async () => {
      try {
        const appTemplates = await client.service(SERVICES.TASK_TEMPLATES).find()
        if (Array.isArray(appTemplates?.data)) {
          setJobTasksTemplates(
            appTemplates.data.map((template: any) => ({
              id: String(template.id),
              status: String(template.status),
              title: String(template.title),
              description: String(template.description),
            }))
          )
        }
      } catch (err: any) {
        console.log("error fetch job tasks>>", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [auth])

  const revokeJobProposal = useCallback(async (param: any) => {
    // const jobs = await jobProposalRevoke(param)
    // if (Array.isArray(jobs?.data)) {
    //   setJobs(jobs.data)
    // }
  }, [])

  const handleDeleteJob = useCallback(async (param: any) => {
    if (auth) {
      const headers = {
        Authorization: `Bearer ${auth.accessToken}`,
      }
      await deleteJob(param, headers)
      fetchJobs()
      return true
    }
  }, [auth])
  const siteRootUrl: string = window.location.origin;
  // const paymentReceivedEmailHandler = useCallback(
  //   async (jobId: any) => {
  //     let INVITE: any;
  //     let customer_name = auth?.user?.name
  //     let customerFirstName = customer_name?.split(" ")?.shift();

  //     let extractedJob = jobs.filter((job: any) => job.id === jobId)[0]
  //     INVITE = paymentTemplate.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Payment]", "$" + extractedJob?.deposit?.toFixed(2)).replaceAll("[Payment Type]", "deposit");

  //     try {
  //       const emailObj = {
  //         to: auth?.user?.email,
  //         subject: paymentTemplate.subject,
  //         text: INVITE,
  //       }
  //       const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
  //       // toast.success(' successfully', {
  //       //   position: toast.POSITION.BOTTOM_CENTER,
  //       // })
  //       // setResendEmail({
  //       //   status:true,
  //       //   email:staff.email
  //       // })
  //     } catch (err: any) {
  //       console.log(err?.message)
  //       // toast.success('Something went wrong!!!', {
  //       //   position: toast.POSITION.BOTTOM_CENTER,
  //       // })
  //     }

  //     // console.log(INVITE)
  //   }, [auth, paymentTemplate, jobs]
  // )

  const buildJobEmailHandler = useCallback(
    async () => {
      let INVITE: any;
      let customer_name = auth?.user?.name
      let customerFirstName = customer_name?.split(" ")?.shift();

      INVITE = template.body.replaceAll("[Customer Name]", customerFirstName || customer_name);

      try {
        const emailObj = {
          to: auth?.user?.email,
          subject: template.subject,
          text: INVITE,
        }
        const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
        // toast.success(' successfully', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
        // setResendEmail({
        //   status:true,
        //   email:staff.email
        // })
      } catch (err: any) {
        console.log(err?.message)
        // toast.success('Something went wrong!!!', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
      }

      // console.log(INVITE)
    }, [auth, template]
  )

  const createJobTasks = async (jobId: any) => {
    let extractedJob = selectedJobDataPayment;
    const headers = {
      Authorization: `Bearer ${auth?.accessToken}`,
    }
    console.log(extractedJob);

    let allPromises: any = [];

    jobTasksTemplates.map((taskTemp: any) => {
      let promise = new Promise(async (resolve, reject) => {
        try {
          const payload = {
            jobId: extractedJob.id,
            title: taskTemp.title,
            description: taskTemp.description,
            status: taskTemp.status,
            assignee: '',
            reporter: 'Reporter',
            set_priority: 'Low',
            estimated_time: '1h 30min',
            logged_time: '1h 30min',
          }
          let data = await client.service(SERVICES.TASK).create(payload, { headers: headers })
          resolve(data)
        } catch (err) {
          reject(err)
        }
      })
      allPromises.push(promise)
    })

    Promise.all(allPromises).then(async (data) => {

      await paymentReceivedEmailHandler("deposit");
      await buildJobEmailHandler();

      toast.success('Success', {
        position: toast.POSITION.BOTTOM_CENTER,
      })
      setTimeout(() => {
        // window.location.reload()
        setPaymentLoader(false);
        navigate(LINKS.PAYMENT_SUCCESS)
      }, 500);
    });

  }

  const isJobActiveHandler = useCallback(
    async (jobId: any) => {
      const jobobj = {
        isJobActive: true,
        // jobStatusChanged: true,
        depositReceived: true,
        jobStatus: "in_progress",
      }


      try {
        await client.service(SERVICES.JOBS).patch(jobId, jobobj)

        await createJobTasks(jobId)
        // setIsJobActive(status)

      } catch (err) {
        toast.error('Something went wrong!!!', {
          position: toast.POSITION.BOTTOM_CENTER,
        })
        // setIsJobActive(false)
        console.log(err)
      }
    }, [template, auth, jobTasksTemplates, createJobTasks]
  )

  const deleteJobPendingtasks = async (jobId: any) => {
    try {
      const query = {
        jobId: jobId,
      }
      const getAllTask = await client.service(SERVICES.TASK).find({ query })
      if (Array.isArray(getAllTask?.data)) {
        let pendingTasks = getAllTask?.data.filter((item: any) => item.status === 'review')
        let promiseArray: any = [];
        pendingTasks.map((task: any) => {
          const promise = new Promise(async (resolve, reject) => {
            try {
              const response = await client.service(SERVICES.TASK).remove(task.id)
              resolve(true)
            } catch (err) {
              reject(false)
            }
          })
          promiseArray.push(promise)
        })

        Promise.all(promiseArray).then(async (result) => {
          // await fetchJob();
          // await fetchTask();
          return true
        })
      }
    } catch (error: any) {
      console.log(error?.message)
    }
  }

  const fullPaymentHandler = useCallback(
    async (jobId: any) => {
      const jobobj = {
        finalPaymentReceived: true
      }

      try {
        await client.service(SERVICES.JOBS).patch(jobId, jobobj)
        await paymentReceivedEmailHandler("final");
        if (selectedJobDataPayment.buildingFinished) {
          await activeJobEmailHandler();
          await deleteJobPendingtasks(jobId);
        }
        setTimeout(() => {
          setPaymentLoader(false)
          navigate(LINKS.PAYMENT_SUCCESS)
        }, 1000);

      } catch (err) {
        toast.error('Something went wrong!!!', {
          position: toast.POSITION.BOTTOM_CENTER,
        })
        // setIsJobActive(false)
        console.log(err)
      }
    }, [auth, activeTemplate, paymentTemplate, selectedJobDataPayment]
  )

  if (loading) {
    return null
  }

  if (isTemp) {
    return null;
  }

  const businessModal = (
    <div className='payment-modal-main'>
      <h4>Business name</h4>
      <p></p>
      <TextField
        label="This name will be used for your website and promotional material."
        id="password"
        placeholder="Type business name..."
        type="text"
        name="password"
        onChange={(e: any) => setBusinessName(e.target.value)}
        // onBlur={handleBlur}
        value={businessName}
      // errors={errors}
      />
      {showBusinessError && <p style={{ color: "red" }}>Required</p>}
    </div>
  )

  const businessModalActionsJSX = (
    <button className="primary button" onClick={async () => {
      if (businessName.trim()?.length === 0) {
        setShowBusinessError(true)
        return;
      }
      if (auth !== null) {
        try {
          const data = await client.service(SERVICES.USERS).patch(auth.user._id, {
            businessName
          })
          setShowBusinessNameModal(false)
          toast.success('Business name updated successfully', {
            position: toast.POSITION.BOTTOM_CENTER,
          })
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } catch (err: any) {
          toast.error('Something went wrong!!!', {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        }
      }
    }} >Update</button>
  )

  const rightSlot = '';

  const fetchJobBySearch = async (value?: string | number, type?: string) => {
    try {
      let jobData;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;

      if (type === 'search' && trimmedValue) {
        const query = { appTitle: trimmedValue };
        jobData = await client.service(SERVICES.JOBS).find({ query });
      } else if (type === 'sort' && (trimmedValue === 1 || trimmedValue === -1)) {
        const query = {
          $sort: { updatedAt: trimmedValue }
        };
        jobData = await client.service(SERVICES.JOBS).find({ query });
      } else if (type === 'filter' && trimmedValue) {
        const query = {
          jobStatus: { $in: [trimmedValue] }
        }
        jobData = await client.service(SERVICES.JOBS).find({ query });

      } else {
        if (trimmedValue == "") {
          const query = {
            $sort: { updatedAt: -1 }
          };
          jobData = await client.service(SERVICES.JOBS).find({ query });
        }
      }

      if (Array.isArray(jobData?.data)) {
        setJobs(jobData.data)
      }
    } catch (error: any) {
      console.log("error", error);

      toast.error('Error fetching job', error?.message);
    }
  };

  /** --------- create app flow Start ---------- */
  const sortOptions = [
    { value: 1, label: "ASC" },
    { value: -1, label: "DESC" }
  ];

  const filterOptions = [
    { value: "new", label: "New" },
    { value: "in_progress", label: "In Progress" },
    { value: "completed", label: "Completed" },
    { value: "review", label: "Review" }
  ];

  const handleFilterChange = (selectedOption: any) => {
    const searchValue = selectedOption.target.value;
    fetchJobBySearch(searchValue, 'filter');
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = Number(event.target.value);
    fetchJobBySearch(selectedOption, 'sort');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    fetchJobBySearch(searchValue, 'search');
  };

  const handelSubmitPayment = (playload: any) => {
    try {
      createAppPaymentHandler(playload, setPatymentDone, setActiveTab, handelUpdateAppAfterPayment);
    } catch (error: any) {
      console.error("Error determining image URL:", error);
      toast.error(error?.message); // Clear the state on error
    }
  }

  /** --------- create app flow End ---------- */
  return (
    <div className="jobs" {...props}>
      {/* <PageHeader
        heading="Apps"
        rightSlot={rightSlot}

      /> */}
      <div className="horizontal-columns-jobs">
        <div className="column-job">
          {showPerview || showAppDemo || showPaymentPage || showSubscriptionPage ?
            <>
              {showPerview && <PreviewTemplateCard setShowPerview={setShowPerview} selectedTemp={selectedTemp} />}
              {showAppDemo && <AppDemoCard />}
              {showPaymentPage && <PatmentTabCard formData={showAppDemoJob} tabs={paymentDetail?.tabs} paymentType={paymentDetail?.paymentType} title={paymentDetail?.title} descPop={paymentDetail?.descPop} desc={paymentDetail?.desc} activeTab={activeTab} setActiveTab={setActiveTab} setSelectedPaymentCard={setSelectedPaymentCard} setUpdatePaymentSubmit={setUpdatePaymentSubmit} patymentDone={patymentDone} />}
              {showSubscriptionPage && <AppSubscriptionCard userJob={UserJob} />}
            </>
            :
            <>
              <div className="column-header" style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <FromHeader
                  title={"My Apps"}
                  desc={"Easily track the status of your apps and stay updated with the latest progress."}
                />
                <div className='' style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%'
                }}>
                  <SearchBarInput onchange={handleSearchChange} />
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}>
                    <SelectOptionDropdown
                      name={'filter'}
                      option={filterOptions}
                      title={'Filter'}
                      icon={<FilterSvg />}
                      onchange={handleFilterChange}
                      style={{
                        width: '200px',
                        paddingLeft: '40px'
                      }}
                      value={``}
                    />
                    <SelectOptionDropdown
                      name={'sort'}
                      option={sortOptions}
                      title={'Sort By'}
                      onchange={handleSortChange}
                      style={{
                        width: '300px',
                        maxWidth: '150px',
                      }}
                      value={``}
                    />
                  </div>
                </div>
              </div>
              <div className="column-body" style={{
                paddingBottom: '120px'
              }}>
                {jobs && jobs.filter((job: any) => job.isSubmitted)?.length > 0 ? (
                  jobs.filter((job: any) => job.isSubmitted).map((job, index) => (
                    <NewJobItem
                      key={index}
                      job={job}
                      handleDeleteJob={() => handleDeleteJob(job.id)}
                      // changeJobStatusHandler={(paymentMethodId: string) => paymentHandler(job.id, paymentMethodId)}
                      paymentHandler={() => {
                        setShowPaymentModal(true)
                        setSelectedJobDataPayment(job)
                      }}
                      setSelectedTemp={setSelectedTemp}
                      setShowPerview={setShowPerview}
                      setShowAppDemo={setShowAppDemo}
                      setShowAppDemoJob={setShowAppDemoJob}
                      setShowSubscriptionPage={setShowSubscriptionPage}
                    />
                  ))
                ) : (
                  <NoJobData
                    icon={<CalendarSvg height={`60px`} width={`60px`} fill={`#00314D`} />}
                    title="No apps have been designed"
                    description="When you design a new app, it'll show here."
                  />
                )}
              </div>
            </>
          }
        </div>

      </div>
      <MyAppsFooter showPaymentPage={showPaymentPage} userJob={UserJob} setShowPerview={setShowPerview} setShowAppDemo={setShowAppDemo} showPerview={showPerview} showAppDemo={showAppDemo} setShowPaymentPage={setShowPaymentPage} templateType={appTemplatePayment} setStep={setStep} step={step} setActiveTab={setActiveTab} handelSubmitPayment={handelSubmitPayment} auth={auth} selectedPaymentCard={selectedPaymentCard} activeTab={activeTab} updatePaymentSubmit={updatePaymentSubmit} setUpdatePaymentSubmit={setUpdatePaymentSubmit} patymentDone={patymentDone} setShowSubscriptionPage={setShowSubscriptionPage} />
      <BaseModal
        isOpen={showBusinessNameModal}
        children={businessModal}
        actionButtons={businessModalActionsJSX}
        handleClose={() => { }}
      />
      <BaseModal
        className='paymentModal'
        isOpen={showPaymentModal}
        children={paymentModalJSX}
        actionButtons={paymentModalActionsJSX}
        handleClose={() => setShowPaymentModal(false)}
      />
    </div>
  )
}

const NewJobItem = ({ job, handleDeleteJob, paymentHandler, setSelectedTemp, setShowPerview, setShowAppDemo, setShowAppDemoJob, setShowSubscriptionPage }: any) => {
  const navigate = useNavigate()
  const [showInfoModalOpen, setShowInfoModalOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [image, setImage] = useState(DEFAULT_AVATAR);

  useEffect(() => {
    const fetchTemplateImage = setTimeout(async () => {
      if (job.appImage) {
        const response: any = await axiosInstance.get(`getSignedFileUrl/?fileName=${job.appImage}`)
        if (response?.status) {
          // console.log(response.data.url);
          setImage(response.data.url)
        }
      }
    }, 200)

    return () => clearTimeout(fetchTemplateImage)
  }, [])

  const kebabMenuItems = useMemo(() => {

    let items = [
      {
        label: 'Show info',
        icon: infoIcon,
        disabled: false,
        onClick: () => {
          setShowInfoModalOpen(!showInfoModalOpen);
        },
      },
      {
        label: 'Delete',
        icon: deleteIcon,
        disabled: false,
        onClick: () => {
          setDeleteOpen(true);
        },
      },
    ];


    return items;
  }, [job, navigate]);

  // const handleHowToBuildClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   if (job && job.id) {
  //     navigate(`/how-to-build/${job.id}`, { state: { jobData: job } });
  //   } else {
  //     console.error("Job or Job ID is not defined.");
  //   }
  // };

  return (
    <>
      <NewJobCard kebabMenuItems={kebabMenuItems} job={job} image={image} setSelectedTemp={setSelectedTemp} setShowPerview={setShowPerview} setShowAppDemo={setShowAppDemo} setShowAppDemoJob={setShowAppDemoJob} setShowSubscriptionPage={setShowSubscriptionPage} />
      <DeleteModal
        isOpen={deleteOpen}
        handleClose={() => {
          setDeleteOpen(false)
        }}
        body={<ModalPreviewJobTemplate job={job} image={image} />}
        handleDelete={async () => {
          if (await handleDeleteJob()) {
            setDeleteOpen(false)
          }
        }}
        title="Delete job"
        subtitle="Do you wish to delete below job?"
      />
      <BaseModal
        isOpen={showInfoModalOpen}
        children={<ShowInfoModal job={job} />}
        handleClose={() => setShowInfoModalOpen(!showInfoModalOpen)}
      />
    </>
  )
}

export default CustomerDashboard

import React, { useState } from 'react';

const AppPlatform: React.FC = () => {
    const [activePlatform, setActivePlatform] = useState<string | null>('Android'); // State to track the active button

    const handleClick = (platform: string) => {
        setActivePlatform(platform); // Set the active platform
        console.log("Platform selected:", platform); // Log selected platform
    };

    return (
        <div style={{ margin: "20px 0" }}>
            <p style={{
                color: "#222647",
                marginBottom: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
            }}>
                App Platform
            </p>
            <div style={{
                display: 'flex',
                marginBottom: "20px",
                gap: 20,
            }}>
                <button
                    style={{
                        background: activePlatform === 'Android' ? '#222647' : '#fff',
                        height: '57px',
                        fontSize: '16px',
                        color: activePlatform === 'Android' ? '#fff' : '#222647',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        width: '120px',
                        padding: '0 30px',
                        border: '1px solid #222647',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleClick('Android')}
                >
                    Android
                </button>
                <button
                    style={{
                        background: activePlatform === 'iOS' ? '#222647' : '#fff',
                        height: '57px',
                        fontSize: '16px',
                        color: activePlatform === 'iOS' ? '#fff' : '#222647',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        width: '120px',
                        padding: '0 30px',
                        border: '1px solid #222647',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleClick('iOS')}
                >
                    iOS
                </button>
                <button
                    style={{
                        background: activePlatform === 'Both' ? '#222647' : '#fff',
                        height: '57px',
                        fontSize: '16px',
                        color: activePlatform === 'Both' ? '#fff' : '#222647',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        width: '120px',
                        padding: '0 30px',
                        border: '1px solid #222647',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleClick('Both')}
                >
                    Both
                </button>
            </div>
        </div>
    );
};

export default AppPlatform;

import React, { useState } from 'react';
import LightOutLined from "pages/customer/CustomerPortal/utils/SVG/lightOutLined";
import MoonIcon from "pages/customer/CustomerPortal/utils/SVG/moonIcon";

const AppMode: React.FC = () => {
    const [activeMode, setActiveMode] = useState<string>('light'); // Track the active mode

    const handleClick = (mode: string) => {
        setActiveMode(mode);
    };

    return (
        <div style={{ margin: "20px 0" }}>
            <p style={{
                color: "#222647",
                marginBottom: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
            }}>
                App Mode
            </p>
            <div style={{
                display: 'flex',
                marginBottom: "20px",
                gap: 20
            }}>
                <button
                    style={{
                        background: activeMode === 'light' ? '#222647' : '#fff',
                        height: '57px',
                        fontSize: '16px',
                        color: activeMode === 'light' ? '#fff' : '#222647',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                        width: '170px',
                        padding: '0 30px',
                        border: '1px solid #222647',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleClick('light')}
                >
                    <LightOutLined /> Light Mode
                </button>
                <button
                    style={{
                        background: activeMode === 'dark' ? '#222647' : '#fff',
                        height: '57px',
                        fontSize: '16px',
                        color: activeMode === 'dark' ? '#fff' : '#222647',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                        width: '170px',
                        padding: '0 30px',
                        border: '1px solid #222647',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleClick('dark')}
                >
                    <MoonIcon /> Dark Mode
                </button>
            </div>
        </div>
    );
};

export default AppMode;

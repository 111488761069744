import { appTypes, appTypesProps } from '../../app';
import StepOneImage from '../../../utils/SVG/setting';
import GreenTickSvg from '../../../utils/SVG/greetick';
import RoundRdioSvg from '../../../utils/SVG/roundRadio';
import CreateAppFooter from '../../footer';
import FromHeader from '../formHeader';
import { onFocusOut, onInputChange } from '../../../../../../components/lib/validation/createApp';

interface Step1Props {
    formData: any;
    nextStep: () => void;
    prevStep: () => void;
    step: number;
    dispatchForm: any;
}

const Step = ({ formData, dispatchForm, nextStep, step, prevStep }: Step1Props) => {

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div className="main_card">
                    <FromHeader
                        title={"How Would You Like to Build Your App?"}
                        desc={"Choose from our ready-made templates for a quick and easy solution, or design a custom app tailored to your specific needs."} step={step} name={formData?.appType} />
                    <div style={{ margin: '70px 0px 0px' }} >
                        <div className="main_section_card"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 8,
                                margin: '20px 40px 40px',
                                cursor: 'pointer',
                                height: "100%",
                                overflow: "auto"

                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    gap: '25px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {appTypes.map((item: appTypesProps, index: number) => (
                                    <div key={index} style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        border: '1px solid rgba(34, 38, 71, 1)',
                                        borderRadius: '4px',
                                        gap: '20'
                                    }}>

                                        <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'space-between', padding: '25px 15px', minHeight: '217px' }} >
                                            {/* Custom SVG for Radio Button */}
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <StepOneImage />
                                                <div className="radio-svg">
                                                    {formData.fields.appType.value === item.title ? (
                                                        <GreenTickSvg />
                                                    ) : (
                                                        <RoundRdioSvg />
                                                    )}
                                                </div>

                                                {/* Radio Button (hidden but still functional) */}
                                            </div>
                                            <input
                                                type="radio"
                                                name="appType"
                                                value={item.title}
                                                checked={formData.fields.appType.value === item.title}
                                                onChange={e => {
                                                    onInputChange("appType", e.target.value, dispatchForm, formData);
                                                }}
                                                onBlur={e => {
                                                    onFocusOut("appType", e.target.value, dispatchForm, formData);
                                                }}
                                                style={{ display: 'none' }} // Hide the default radio button
                                            />
                                            {/* Text Content */}
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', gap: '15px' }}>
                                                <div style={{ fontWeight: '600', fontSize: '20px' }}>{item.title}</div>
                                                <div style={{ fontWeight: '500', fontSize: '18px' }}>{item.desc}</div>
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            {formData.fields.appType.touched && formData.fields.appType.hasError && (
                                <div className="error">{formData.fields.appType.error}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <CreateAppFooter nextStep={nextStep} step={step} prevStep={prevStep} price={"0"} type={0} setActiveTab={0} />
        </div >
    )
};
export default Step;
import './index.sass'
import SearchIcon from 'pages/customer/CustomerPortal/utils/SVG/searchIcon';

interface Props {
    name?: string;
    value?: any;
    onchange?: (e: React.ChangeEvent<HTMLInputElement>) => void; 
}

const SearchBarInput = ({onchange,value,name}:Props) => {
    return (
        <div className="searchBar">
            <button id="searchQuerySubmit" type="submit" name={name} >
                <SearchIcon />
            </button>
            <input id="searchQueryInput" type="text" name="search" placeholder="Search" value={value} onChange={onchange} />
        </div>
    )
}
export default SearchBarInput; 
import CloseIcone from 'pages/customer/CustomerPortal/utils/SVG/closeIcon';
import './index.sass'
import StatusPopup from 'components/common/statusPopup';

interface Props {
    title?: string;
    desc?: string;
    setSucessPop?:any;
    SucessPop?:any;
    setFile?:any;
}

const SimplePopup = ({ title, desc,setSucessPop,SucessPop,setFile }: Props) => {
    
    const handelClose = () =>{
        setSucessPop(false);
        setFile(null);
    }

    return (
        <div className={`popup-container ${!SucessPop ? 'popup-style-2' : 'popup-style'}`}>
            <div className="popup-content">
                <a href="#" className="close" onClick={handelClose}><CloseIcone/></a>
                <StatusPopup title={title} desc={desc} type={'pop'} />
            </div>
        </div>
    )
} 
export default SimplePopup;
import { createBrowserRouter } from 'react-router-dom'

import Root from 'routes/Root'

import RouteError from 'pages/RouteError'
import Dashboard from 'pages/dashboard/Dashboard'
import AddTask from 'pages/jobs/tasks/AddTask'
import CreateJob from 'pages/jobs/CreateJob/CreateJob'
import { TaskDetails } from 'pages/jobs/tasks/TaskDetails'
import JobDetails from 'pages/jobs/JobDetails'
import Jobs from 'pages/jobs/Jobs'
import PendingJobDetails from 'pages/jobs/PendingJobDetails'
import Login from 'pages/login/Login'
import LoginRecovery from 'pages/login/LoginRecovery'
import LoginRoot from 'pages/login/LoginRoot'
import LoginUpdate from 'pages/login/LoginUpdate'
import Notifications from 'pages/notifications/Notifications'
import EditStaffDetail from 'pages/staff/EditStaffDetail'
import PendingStaffDetails from 'pages/staff/PendingStaffDetail'
import Staff from 'pages/staff/Staff'
import StaffInvlite from 'pages/staff/StaffInvite'
import StaffInvitation from 'pages/staff/StaffInvitation'
import AddAppTemplate from 'pages/templates/AddAppTemplate'
import AddEmailTemplate from 'pages/templates/AddEmailTemplate'
import AppTemplates from 'pages/templates/AppTemplates'
import EditAppTemplate from 'pages/templates/EditAppTemplate'
import EditEmailTemplate from 'pages/templates/EditEmailTemplate'
import EmailTemplates from 'pages/templates/EmailTemplates'
import Templates from 'pages/templates/Templates'

import { LINKS } from './constants'
import CustomerPortal from 'pages/customer'
import CustomerDashboard from 'pages/customer/CustomerPortal'
import PendingTask from 'pages/customer/PendingTask'
import CustomerUsers from 'pages/users'
import AddItem from 'pages/customer/CustomerPortal/inventory/AddItem'
import StartPage from 'pages/start'
import EditProfile from 'pages/settings/EditProfile'
import UserSubscription from 'pages/settings/Subscription'
import Newjobs from 'pages/jobs/NewJobs'
import ActiveJobs from 'pages/jobs/ActiveJobs'
import InactiveJobs from 'pages/jobs/InactiveJobs'
import TaskTemplates from 'pages/templates/task/TaskTemplates'
import AddTaskTemplate from 'pages/templates/task/AddTaskTemplate'
import EditTaskTemplate from 'pages/templates/task/EditTaskTemplate'
import AssetsPage from 'pages/customer/assetspage'
import Payments from 'pages/customer/payments'
import PaymentSuccess from 'pages/payments/Success'
import PaymentError from 'pages/payments/Error'
import PaymentsAddCard from 'pages/customer/payments/addCard'
import PaymentsEditCard from 'pages/customer/payments/editCard'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Subscriptions from 'pages/subscriptions/Subscriptions'
import AddSubscription from 'pages/subscriptions/AddSubscription'
import EditSubscription from 'pages/subscriptions/EditSubscription'

import PredefinedPage from 'pages/customer/predefined'
import CustomPage from 'pages/customer/custom'
import HowToBuild from 'pages/customer/HowToBuild'
import CreateApp from 'pages/customer/CustomerPortal/CreateApp'


const stripePromise = loadStripe("pk_test_51P6o5wAON2BF3DZ1LDszecnulsd2DSXVMzToOSj5wW4pS3CGjMgWd2wATOAB0RChyI3L0mdjC1EGZbWmuUvwoevS0054jaobzs");


const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <RouteError />,
    children: [
      {
        path: LINKS.DASHBOARD,
        element: <Dashboard />,
      },
      {
        path: LINKS.JOBS,
        element: <Jobs />,
      },
      {
        path: LINKS.JOBS_PENDING,
        element: <Jobs />,
      },
      {
        path: LINKS.JOBS_PENDING_ITEM,
        element: <PendingJobDetails />,
      },
      {
        path: LINKS.JOBS_DETAILS,
        element: <JobDetails />,
      },
      {
        path: LINKS.NEWJOBS,
        element: <Newjobs />,
      },
      {
        path: LINKS.ACTIVEJOBS,
        element: <ActiveJobs />,
      },
      {
        path: LINKS.INACTIVEJOBS,
        element: <InactiveJobs />,
      },
      {
        path: LINKS.JOBS_CREATE,
        element: <CreateJob />,
      },
      {
        path: LINKS.STAFF,
        element: <Staff />,
      },
      {
        path: LINKS.STAFF_INVITE,
        element: <StaffInvlite />,
      },
      {
        path: LINKS.STAFF_PENDING,
        element: <Staff />,
      },
      {
        path: LINKS.STAFF_PENDING_DETAIL,
        element: <PendingStaffDetails />,
      },
      {
        path: LINKS.STAFF_EDIT,
        element: <EditStaffDetail />,
      },
      {
        path: LINKS.TEMPLATES,
        element: <Templates />,
      },
      {
        path: LINKS.TEMPLATES_EMAIL,
        element: <EmailTemplates />,
      },
      {
        path: LINKS.TEMPLATES_APP,
        element: <AppTemplates />,
      },
      {
        path: LINKS.TEMPLATES_APP_ADD,
        element: <AddAppTemplate />,
      },
      {
        path: LINKS.TEMPLATES_APP_VIEW,
        element: <EditAppTemplate />,
      },
      {
        path: LINKS.TEMPLATES_TASK,
        element: <TaskTemplates />,
      },
      {
        path: LINKS.TEMPLATES_TASK_ADD,
        element: <AddTaskTemplate />,
      },
      {
        path: LINKS.TEMPLATES_TASK_VIEW,
        element: <EditTaskTemplate />,
      },
      {
        path: LINKS.TEMPLATES_EMAIL_ADD,
        element: <AddEmailTemplate />,
      },
      {
        path: LINKS.TEMPLATES_EMAIL_VIEW,
        element: <EditEmailTemplate />,
      },
      {
        path: LINKS.SUBSCRIPTION,
        element: <Subscriptions />
      },
      {
        path: LINKS.SUBSCRIPTION_ADD,
        element: <AddSubscription />
      },
      {
        path: LINKS.SUBSCRIPTION_VIEW,
        element: <EditSubscription />
      },
      {
        path: LINKS.NOTIFICATIONS,
        element: <Notifications />,
      },
      {
        path: LINKS.JOBS_ADDTASK,
        element: <AddTask />,
      },
      {
        path: LINKS.TASKS_EDIT,
        element: <TaskDetails />,
      },
      {
        path: LINKS.CUSTOMER_USERS,
        element: <CustomerUsers />
      },
      // {
      //   path: LINKS.CUSTOMER_INVENTORY_ADDITEM,
      //   element: <AddItem />
      // },
      {
        path: LINKS.CustomerDashboard,
        element: <CustomerDashboard />
      },
      {
        path: LINKS.CUSTOMERASSETS,
        element: <AssetsPage />
      },
      {
        path: LINKS.CUSTOMER_PAYMENTS,
        element:
          <Elements stripe={stripePromise}>
            <Payments />
          </Elements>
      },
      {
        path: LINKS.PendingTask,
        element: <PendingTask />
      },
      {
        path: LINKS.EDITPROFILE,
        element: <EditProfile />
      },
      {
        path: LINKS.USER_SUBSCRIPTION,
        element: <UserSubscription />
      },
      {
        path: LINKS.PAYMENT_SUCCESS,
        element: <PaymentSuccess />
      },
      {
        path: LINKS.PAYMENT_FAILED,
        element: <PaymentError />
      },
      {
        path: LINKS.PAYMENT_AddCard,
        element: <PaymentsAddCard />
      },
      {
        path: LINKS.PAYMENT_EditCard,
        element: <PaymentsEditCard />
      },
      {
        path: LINKS.CUSTOMER_predefined,
        element: <PredefinedPage />
      },
      {
        path: LINKS.CUSTOMER_APPS_CREATE,
        element: <CreateApp />
      },
      {
        path: LINKS.CUSTOMER_custom,
        element: <CustomPage />
      },
      {
        path: LINKS.HOW_TO_BUILD,
        element: <HowToBuild />
      }
    ],
  },
  {
    path: LINKS.LOGIN,
    element: <LoginRoot />,
    children: [
      {
        path: '',
        element: <Login />,
      },
      {
        path: LINKS.LOGIN_RECOVERY,
        element: <LoginRecovery />,
      },
      {
        path: LINKS.PASSWORD_UPDATE,
        element: <LoginUpdate />,
      },
    ],
  },
  {
    path: LINKS.CUSTOMER_ONBOARDING,
    element: <CustomerPortal />
  },
  {
    path: LINKS.STAFF_INVITATION,
    element: <StaffInvitation />
  },
  {
    path: LINKS.START_PAGE,
    element: <StartPage />
  },

])

export default router

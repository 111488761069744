import React, { useEffect, useState } from 'react';
import CreateAppFooter from '../../footer';
import FromHeader from '../formHeader';
import { onFocusOut, onInputChange } from '../../../../../../components/lib/validation/createApp';

interface Step1Props {
    formData: any;
    nextStep: () => void;
    prevStep: () => void;
    step: number;
    name: string;
    dispatchForm: any;
    preview:any;
    setPreview:any;
}

const Step3 = ({ formData, dispatchForm, nextStep, step, name,setPreview,prevStep,preview }: Step1Props) => {
    const [file, setFile] = useState<string | null>(null);
    // Handle image removal
    const handleRemoveImage = () => {
        setFile(null);
        setPreview(null);
        onInputChange("attachment", '', dispatchForm, formData);
    };

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]; // Get the first file
        if (file) {
            setPreview(file);
            const fileUrl = URL.createObjectURL(file);
            setFile(fileUrl);
            onInputChange("attachment", file?.name, dispatchForm, formData);
        }
    };
    
    useEffect(() => {
        if(preview){
            const fileUrl = URL.createObjectURL(preview);
            setFile(fileUrl);
        }
    }, [preview]);

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div className="main_card" style={{
                    width: '100%'
                }}>
                    <FromHeader
                        title={"Customization Details"}
                        desc={"Provide the details to personalize your app according to your business need."}
                        step={step}
                        name={name}
                    />
                    <div className="main_section_card"
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 8,
                            margin: '20px 40px 40px',
                            cursor: 'pointer',
                            height: "100%",
                            overflow: "auto"
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                gap: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                borderRadius: '4px',
                                gap: 25
                            }}>
                                <div style={{
                                    width: '48%',
                                }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Write the display name of your app
                                    </label>
                                    <input
                                        name="displayName"
                                        value={formData.fields.displayName?.value}
                                        className={`${formData.fields.displayName.touched && formData.fields.displayName.hasError ? "error" : ""}`}
                                        onChange={e => {
                                            onInputChange("displayName", e.target.value, dispatchForm, formData);
                                        }}
                                        onBlur={e => {
                                            onFocusOut("displayName", e.target.value, dispatchForm, formData);
                                        }}
                                        placeholder='Enter your app name'
                                        style={{
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            fontSize: '14px',
                                        }}
                                    />
                                    {formData?.displayName?.touched && formData?.displayName?.hasError && (
                                        <div className="error">{formData?.displayName?.error}</div>
                                    )}
                                </div>
                                <div style={{
                                    width: '48%',
                                }}>
                                    <label
                                        style={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Business Website URL (Optional)
                                    </label>
                                    <input
                                        name="website"
                                        value={formData.fields.website.value}
                                        className={`${formData.fields.website.touched && formData.fields.website.hasError ? "error" : ""}`}
                                        onChange={e => {
                                            onInputChange("website", e.target.value, dispatchForm, formData);
                                        }}
                                        onBlur={e => {
                                            onFocusOut("website", e.target.value, dispatchForm, formData);
                                        }}
                                        placeholder='https//:www.abc.com'
                                        style={{
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            fontSize: '14px',
                                        }}
                                    />
                                    {formData?.website?.touched && formData?.website?.hasError && (
                                        <div className="error">{formData?.website?.error}</div>
                                    )}
                                </div>
                            </div>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '4px',
                                gap: '20'
                            }}>
                                <label
                                    style={{
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Would you like to inform our team of anything? (Optional)
                                </label>

                                {/* Textarea input */}
                                <textarea
                                    name="message"
                                    value={formData.fields.message.value}
                                    placeholder='Your message...'
                                    className={`${formData.fields.message.touched && formData.fields.message.hasError ? "error" : ""}`}
                                    onChange={e => {
                                        onInputChange("message", e.target.value, dispatchForm, formData);
                                    }}
                                    onBlur={e => {
                                        onFocusOut("message", e.target.value, dispatchForm, formData);
                                    }}
                                    rows={4}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        resize: 'vertical',
                                        fontSize: '14px',
                                    }}
                                />
                                {formData?.message?.touched && formData?.message?.hasError && (
                                    <div className="error">{formData?.message?.error}</div>
                                )}
                            </div>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                borderRadius: '4px',
                            }}>
                                <label
                                    style={{
                                        fontWeight: 'bold'
                                    }}
                                >
                                    File Attachment
                                </label>

                                <input
                                    name="attachment"
                                    type='file'
                                    className={`${formData.fields.attachment.touched && formData.fields.attachment.hasError ? "error" : ""}`}
                                    onChange={e => {
                                        handleChangeFile(e);
                                    }}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        borderRadius: '4px',
                                    }}
                                />
                                {formData?.attachment?.touched && formData?.attachment?.hasError && (
                                    <div className="error">{formData?.attachment?.error}</div>
                                )}
                                {file && (
                                    <div style={{
                                        position: 'relative',
                                        marginTop: '20px',
                                        border: '1px solid #ccc',
                                        padding: '10px'
                                    }}>
                                        <img src={file} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                        <a type="button" onClick={handleRemoveImage} style={{
                                            position: 'absolute',
                                            right: '-15px',
                                            top: '-10px',
                                            fontSize: '24px',
                                            fontWeight: '600',
                                            border: '1px solid #ccc',
                                            borderRadius: '50%',
                                            width: '30px',
                                            height: '30px',
                                            textAlign: 'center',
                                            lineHeight: '23px',
                                            cursor: 'pointer',
                                            color: '#000',
                                            background: '#fff'
                                        }}>
                                            x
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateAppFooter nextStep={nextStep} step={step} prevStep={prevStep} price={"0"} type={0} setActiveTab={0} />
        </div >
    );
};

export default Step3;

function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}

import { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';


interface FooterProps {
    step?: number;
    nextStep?: () => void;
    prevStep?: () => void;
    price?: string;
    type?: number;
    setActiveTab?: any;
    prevBtn?: boolean;
    setShowPerview?: any;
    setShowAppDemo?: any;
    showPerview?: boolean;
    showAppDemo?: boolean;
    setShowPaymentPage?: any;
    userJob?: any;
    showPaymentPage?: any;
    setStep?: any;
    handelSubmitPayment?: any;
    auth?: any;
    selectedPaymentCard?: any;
    templateType?: any;
    activeTab?: any;
    updatePaymentSubmit: any;
    setUpdatePaymentSubmit?: any;
    patymentDone?: any;
    setShowSubscriptionPage?: any;
}

const MyAppsFooter = ({ step, setActiveTab, setShowPerview, setShowAppDemo, showPerview, showAppDemo, setShowPaymentPage, userJob, showPaymentPage, setStep, handelSubmitPayment, auth, selectedPaymentCard, templateType, activeTab, setUpdatePaymentSubmit, updatePaymentSubmit, patymentDone, setShowSubscriptionPage }: FooterProps) => {

    const navigate = useNavigate();
    const [readyToPayment, setReadyToPayment] = useState<boolean>(false)

    const UserJob: any = useSelector((state: RootState) => state.createAppReducer.job);

    const handelBack = () => {
        setUpdatePaymentSubmit(false)
        setShowPerview(false);
        setShowAppDemo(false);
        setShowSubscriptionPage(false);
        setShowPaymentPage(false);
        navigate('/customer');
    }

    const handelPaymentPage = () => {
        setStep(1)
        setActiveTab(0);
        setShowPaymentPage(true)
        setShowAppDemo(false);
    }

    const handelPaymentComponet = () => {
        if (step === 1 && !updatePaymentSubmit) {
            setActiveTab(1);
            setUpdatePaymentSubmit(true)
        }
        if (activeTab == 1) {
            const playload = {
                user_Id: auth?.user?._id,
                customerId: auth?.user?.stripeCustomerId,
                paymentMethodId: selectedPaymentCard?.id,
                amount: userJob?.interimPaymentReceived === false ? templateType?.interim : templateType?.finalPayment,
                description: "Your payment description here",
                jobId: UserJob?.id,
                paymentType: userJob?.interimPaymentReceived === false ? 'interim' : 'final',
            };
            handelSubmit(playload);
            setUpdatePaymentSubmit(false)
        }
    }

    const handelSubmit = (playload: any) => {
        updatePaymentSubmit && handelSubmitPayment(playload);
    }

    return (
        <div style={{ width: '100%', padding: '15px', position: 'absolute', right: '0', bottom: '0px', borderTop: '1px solid #DAE3EA', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#fff' }}>
            <div style={{ width: '50%', textAlign: 'left', fontSize: '20px', fontWeight: 'bold', color: '#265068' }}>
                {showPaymentPage && `$${userJob?.interimPaymentReceived === false ? templateType?.interim : userJob?.interimPaymentReceived === true && userJob?.finalPaymentReceived === false && templateType?.finalPayment}`
                }
            </div>
            <div style={{ width: '50%', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <>
                    {
                        patymentDone ? (
                            <Button className="newDark" href="/customer">
                                Back to My Apps
                            </Button>
                        ) : (
                            <>
                                <Button
                                    onClick={() => handelBack()}
                                    className="newLight"
                                    style={{ width: '150px' }}
                                >
                                    Back
                                </Button>

                                {showAppDemo && (
                                    <Button className="newDark" onClick={handelPaymentPage}>
                                        {userJob?.interimPaymentReceived === false
                                            ? 'Interim Payment'
                                            : 'Final Payment'}
                                    </Button>
                                )}
                                {showPaymentPage && (userJob?.interimPaymentReceived === false || userJob?.finalPaymentReceived === false) ? (
                                    <Button className="newDark" onClick={handelPaymentComponet}>
                                        Pay Now
                                    </Button>
                                ) : (
                                    !showAppDemo && (
                                        <Button className="newDark">
                                            Create New App
                                        </Button>
                                    )
                                )}
                            </>
                        )}
                </>
            </div>
        </div>
    )
}
export default MyAppsFooter;
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "pages/customer/payments/form";
import { stripePromise } from "utils/constants";

interface props {
    stepType:any;
    setActiveTab:any;
    setSelectedPaymentCard?:any;
}

const AddCard = ({stepType,setActiveTab,setSelectedPaymentCard}:props) => {
    return (
        <Elements stripe={stripePromise}>
            <CardForm formid={``} type={`add`} stepType={stepType} setActiveTab={setActiveTab} setSelectedPaymentCard={setSelectedPaymentCard} />
        </Elements>
    );
}
export default AddCard;
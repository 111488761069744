import React, { Dispatch } from "react";

export const UPDATE_FORM = "UPDATE_FORM";
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";


// Example initial state
export const initialState: FormState = {
    fields: {
        appType: { value: "", hasError: false, error: "", touched: false },
        name: { value: "", hasError: false, error: "", touched: false },
        template: { value: "", hasError: false, error: "", touched: false },
        template_id: { value: "", hasError: false, error: "", touched: false },
        displayName: { value: "", hasError: false, error: "", touched: false },
        website: { value: "", hasError: false, error: "", touched: false },
        message: { value: "", hasError: false, error: "", touched: false },
        price: { value: "", hasError: false, error: "", touched: false },
        attachment: { value: "", hasError: false, error: "", touched: false },
    },
    isFormValid: false,
    currentStep: 0,
};

type FormField = {
    value: string;
    hasError: boolean;
    error: string;
    touched: boolean;
};

type FormState = {
    fields: { [key: string]: FormField }; // The actual fields of the form
    isFormValid: boolean;                  // Overall form validity
    currentStep: number;                   // Current step in the multi-step form process
};

type FormAction =
    | {
          type: typeof UPDATE_FORM;
          data: {
              name: string;
              value: string;
              hasError: boolean;
              error: string;
              touched: boolean;
              isFormValid: boolean;
          };
      }
    | { type: typeof NEXT_STEP | typeof PREVIOUS_STEP };


const steps: Record<number, string[]> = {
    0: ["appType"],
    1: ["name"],
    2: ["template", "template_id", "price"],
    3: ["displayName", "website", "message", "attachment"],
};

export const validateInput = (
    name: string,
    value: string,
    formFields: { [key: string]: FormField },  // Correctly typed field object
    currentStep: number  // Pass current step from form state
) => {
    let hasError = false;
    let error = "";

    // Define form steps
    const steps: Record<number, string[]> = {
        0: ["appType"],
        1: ["name"],
        2: ["template", "template_id", "price"],
        3: ["displayName", "website", "message", "attachment"],
    };

    // Determine if the current field belongs to the current step
    if (!steps[currentStep]?.includes(name)) {
        return { hasError: false, error: "" }; // Skip validation if not relevant to current step
    }

    switch (name) {
        case "appType":
            if (value.trim() === "") {
                hasError = true;
                error = "Please select at least one App Type.";
            }
            break;
        case "name":
            if (value.trim() === "") {
                hasError = true;
                error = "Name is required.";
            }
            break;
        case "template":
            if (value.trim() === "") {
                hasError = true;
                error = "Template is required.";
            }
            break;
        case "template_id":
            if (value.trim() === "") {
                hasError = true;
                error = "Template ID is required.";
            }
            break;
        case "displayName":
            if (value.trim() === "") {
                hasError = true;
                error = "Display Name is required.";
            }
            break;
        // case "website":
        //     if (!/^https?:\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
        //         hasError = true;
        //         error = "Please enter a valid URL.";
        //     }
        //     break;
        // case "message":
        //     if (value.trim() === "") {
        //         hasError = true;
        //         error = "Message is required.";
        //     }
        //     break;
        case "price":
            if (isNaN(Number(value)) || Number(value) <= 0) {
                hasError = true;
                error = "Price must be a positive number.";
            }
            break;
        case "attachment":
            if (value.trim() === "") {
                hasError = true;
                error = "Attachment is required.";
            }
            break;
        // Add more cases as needed for other fields
    }

    return { hasError, error };
};

export const onInputChange = (
    name: string,
    value: string,
    dispatch: Dispatch<FormAction>,
    formState: FormState
) => {

    const { hasError, error } = validateInput(name, value, formState.fields, formState.currentStep);
    const isFormValid = Object.values(formState.fields).every((field) => !field.hasError);

    dispatch({
        type: UPDATE_FORM,
        data: { 
            name, 
            value, 
            hasError, 
            error, 
            touched: true, 
            isFormValid 
        },
    });

};


export const onFocusOut = (
    name: string,
    value: string,
    dispatch: Dispatch<FormAction>,
    formState: FormState
) => {
    // Validate the input and get error status
    const { hasError, error } = validateInput(name, value, formState.fields, formState.currentStep);

    // Update field and mark as touched
    dispatch({
        type: UPDATE_FORM,
        data: { 
            name, 
            value, 
            hasError, 
            error, 
            touched: true, 
            isFormValid: Object.values(formState.fields).every((field) => !field.hasError) 
        },
    });
};


export const goToNextStep = (formState: FormState, dispatch: Dispatch<FormAction>) => {
    const currentStepFields = steps[formState.currentStep];
    let isStepValid = true;

    for (const field of currentStepFields) {
        if (formState.fields[field].hasError || formState.fields[field].value.trim() === "") {
            isStepValid = false;
            break;
        }
    }

    if (isStepValid) {
        dispatch({ type: NEXT_STEP });
    } else {
        console.log("Please complete the current step before proceeding.");
    }
};



export const goToPreviousStep = (dispatch: Dispatch<FormAction>) => {
    dispatch({ type: PREVIOUS_STEP });
};

export const formReducer = (state: FormState, action: FormAction): FormState => {
    switch (action.type) {
        case UPDATE_FORM: {
            const { name, value, hasError, error, touched, isFormValid } = action.data;
            const updatedField = { ...state.fields[name], value, hasError, error, touched };
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [name]: updatedField,
                },
                isFormValid, // Ensuring form validity is updated correctly
            };
        }
        case NEXT_STEP: {
            return {
                ...state,
                currentStep: state.currentStep + 1, // Increment current step
            };
        }
        case PREVIOUS_STEP: {
            return {
                ...state,
                currentStep: state.currentStep - 1, // Decrement current step
            };
        }
        default:
            return state;
    }
};

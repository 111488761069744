interface Props {
    width:string;
    height:string;
}

const PlaceholderSvg = ({width,height}:Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 345 198" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
            display: 'flex',
            width:'100%',
            justifyContent: 'center'
        }}>
            <path d="M0 9.6C0 6.23969 0 4.55953 0.653961 3.27606C1.2292 2.14708 2.14708 1.2292 3.27606 0.653961C4.55953 0 6.23969 0 9.6 0H316.4C319.76 0 321.44 0 322.724 0.653961C323.853 1.2292 324.771 2.14708 325.346 3.27606C326 4.55953 326 6.23969 326 9.6V198H0V9.6Z" fill="#EBEBEB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M190 78V120C190 123.3 187.3 126 184 126H142C138.7 126 136 123.3 136 120V78C136 74.7 138.7 72 142 72H184C187.3 72 190 74.7 190 78ZM160 112.53L153.7 104.94C153.07 104.19 151.93 104.22 151.36 105L143.89 114.6C143.11 115.59 143.8 117.03 145.06 117.03H181.03C182.26 117.03 182.98 115.62 182.23 114.63L171.7 100.59C171.1 99.78 169.9 99.78 169.3 100.56L160 112.53Z" fill="#94A3B1" />
        </svg>
    )
}
export default PlaceholderSvg;
